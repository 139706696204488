var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { OnInit, EventEmitter } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { FileStorageService, StorageStrategies, UploadStrategies } from '@app/shared/common/file-uploader/service/file-storage.service';
import { last, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { MessageService } from '@abp/message/message.service';
import { AuthService } from '../IDSVRAuthProviders/auth.service';
import { BsModalRef } from 'ngx-bootstrap';
var FileUploaderComponent = /** @class */ (function () {
    function FileUploaderComponent(fileStorageService, msgService, authService, bsModalRef) {
        this.fileStorageService = fileStorageService;
        this.msgService = msgService;
        this.authService = authService;
        this.bsModalRef = bsModalRef;
        this.accepts = '';
        this.maxFileSize = 1000000;
        // @Input('file-limit') public fileLimit = 10;
        this.disabled = false;
        this.allowMultiple = false;
        this.uploadStrategy = UploadStrategies.Simultaneous;
        this.fileTags = [];
        this.optionalHeaderText = '';
        this.showSuccess = true;
        this.uploadFinished = new EventEmitter();
        this.isUploading = false;
        this.isProcessing = false;
    }
    FileUploaderComponent.prototype.ngOnInit = function () {
        this.checkStorageStrategy();
    };
    /**
     * Event Handler for the request to upload files. This will upload based on the selected Upload Strategy.
     * @param event The event that occurred when the request to upload was made.
     */
    FileUploaderComponent.prototype.uploadHandler = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.checkStorageStrategy();
                        if (!(!this.isUploading && this.fileUploader.files.length > 0)) return [3 /*break*/, 11];
                        this.isUploading = true;
                        this.fileUploader.disabled = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 9, 10, 11]);
                        _a = this.uploadStrategy;
                        switch (_a) {
                            case UploadStrategies.Sequential: return [3 /*break*/, 2];
                            case UploadStrategies.Simultaneous: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, this.uploadFilesSequentially()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 4: return [4 /*yield*/, this.uploadFilesSimultaneously()];
                    case 5:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.uploadFilesSequentially()];
                    case 7:
                        _b.sent();
                        return [3 /*break*/, 8];
                    case 8:
                        if (this.showSuccess) {
                            this.msgService.success('Uploaded Successfully');
                        }
                        this.fileUploader.clear();
                        return [3 /*break*/, 11];
                    case 9:
                        e_1 = _b.sent();
                        this.msgService.error('An error occurred while uploading your files');
                        return [3 /*break*/, 11];
                    case 10:
                        this.isUploading = false;
                        this.fileUploader.disabled = false;
                        this.fileUploader.progress = 0;
                        return [7 /*endfinally*/];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Event Handler to update the progress of a file upload.
     * @param $event The event that occurred. Contains the current progress value.
     */
    FileUploaderComponent.prototype.progressReport = function ($event) {
        this.fileUploader.progress = $event;
    };
    /**
     * Removes a file from the list of files to be uploaded.
     * @param event Event that occurred when this method was triggered.
     * @param file The selected file to be removed.
     */
    FileUploaderComponent.prototype.removeFile = function (event, file) {
        this.fileUploader.remove(event, this.fileUploader.files.indexOf(file));
    };
    /**
     * Formats the raw byte total of a file into a human-readable value.
     * @param bytes The raw byte count of the file
     * @param decimals The number of decimals to be displayed
     */
    FileUploaderComponent.prototype.formatBytes = function (bytes, decimals) {
        if (decimals === void 0) { decimals = 2; }
        if (!+bytes) {
            return '0 Bytes';
        }
        decimals = decimals < 0 ? 0 : decimals;
        var divisor = 1024;
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var index = Math.floor(Math.log(+bytes) / Math.log(divisor));
        return parseFloat((+bytes / Math.pow(divisor, index)).toFixed(decimals)) + " " + sizes[index];
    };
    /**
     * Checks if the optional header text is present.
     * @returns {boolean} True if the optional header text is present, false otherwise.
     */
    FileUploaderComponent.prototype.hasOptionalHeader = function () {
        return this.optionalHeaderText !== null && this.optionalHeaderText !== undefined && this.optionalHeaderText !== '';
    };
    /**
     * Checks that there is a valid Storage Strategy provided in the instantiation of the uploader.
     * @private
     */
    FileUploaderComponent.prototype.checkStorageStrategy = function () {
        if (this.storageStrategy === null || this.storageStrategy === undefined) {
            throw Error('Please select a storage strategy');
        }
        if (!Object.values(StorageStrategies).includes(this.storageStrategy)) {
            throw Error('The Storage Strategy provided is invalid');
        }
    };
    /**
     * Prepares the form data to be uploaded.
     * @param files The files to be uploaded
     * @private
     */
    FileUploaderComponent.prototype.prepareFormData = function (files) {
        var formData = new FormData();
        formData.append('fileStorageStrategy', this.storageStrategy.toString());
        formData.append('clientId', this.authService.user.profile.sub);
        formData.append('accessToken', this.authService.user.access_token);
        files.forEach(function (file, index) {
            formData.append('files', file);
        });
        this.fileTags.forEach(function (tag, index) {
            formData.append('tags', tag);
        });
        return formData;
    };
    /**
     * Prepares and uploads each added file individually.
     * @private
     */
    FileUploaderComponent.prototype.uploadFilesSequentially = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2, _a, _b, _c, file, formData, e_2_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 5, 6, 7]);
                        _b = __values(this.fileUploader.files), _c = _b.next();
                        _d.label = 1;
                    case 1:
                        if (!!_c.done) return [3 /*break*/, 4];
                        file = _c.value;
                        this.fileUploader.progress = 0;
                        formData = this.prepareFormData([file]);
                        return [4 /*yield*/, this.processUpload(formData)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        _c = _b.next();
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 7];
                    case 6:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Prepares and uploads all added files simultaneously.
     * @private
     */
    FileUploaderComponent.prototype.uploadFilesSimultaneously = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = this.prepareFormData(this.fileUploader.files);
                        return [4 /*yield*/, this.processUpload(formData)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Handles the upload and progress reporting for display on the UI.
     * @param formData The data to be uploaded
     * @private
     */
    FileUploaderComponent.prototype.processUpload = function (formData) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.fileUploader.progress = 0;
                        _this.fileStorageService.upload(formData).pipe(tap(function (event) {
                            if (event.type === HttpEventType.UploadProgress) {
                                _this.fileUploader.onProgress.emit(event.loaded / event.total * 100);
                            }
                        }), last()).subscribe({
                            next: function (val) {
                                if (val.type === HttpEventType.Response) {
                                    _this.uploadFinished.emit(val.body);
                                }
                                _this.fileUploader.onProgress.emit(100);
                                resolve();
                            },
                            error: function (err) { return reject(err); }
                        });
                    })];
            });
        });
    };
    FileUploaderComponent.prototype.closeModal = function () {
        this.bsModalRef.hide();
    };
    return FileUploaderComponent;
}());
export { FileUploaderComponent };
