import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap';
import { PaginatorModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { YodleeModalComponent } from '@app/main/human-api-authentication/yodlee-modal/yodlee-modal.component';
import { MyCreditModalComponent } from '@app/main/my-credit/my-credit-modal/my-credit-modal.component';
import { MyHealthCareModalComponent } from '@app/main/my-healthcare/my-health-care-modal/my-health-care-modal.component';
import { MyWearablesModalComponent } from '@app/main/my-wearables/my-wearables-modal/my-wearables-modal.component';
import { ImagePipe, CurrencyPipeZar, MyFilterPipe, QuestionableBooleanPipe, TruncateUnderscorePipe, YearsPipe, GroupByPipe, FilterByPipe, TruncateDecimalPipe, ThousandSeperatorPipe, ArraySortPipe, RemoveAllUnderscore } from '@shared/common/pipes/commonElevate.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionModalComponent } from './widgets/action-modal/action-modal.component';
import { NumberDirective } from '../../../shared/common/directive/commmon-directive';
import { QuoteModalComponent } from './widgets/my-needs-calculator-widget/my-needs-calculator/estimate-stepper/quote-modal/quote-modal.component';
import { DeclineModalComponent } from './modals/decline-modal/decline-modal.component';
import { SupportModalComponent } from './modals/support-modal/support-modal.component';
import { CustomDatePickerComponent } from './custom-input/custom-date-picker/custom-date-picker.component';
import { SupportFloaterComponent } from './support-floater/support-floater.component';
import { ConfirmProductPremiumModalComponent } from './modals/confirm-product-premium-modal/confirm-product-premium-modal.component';
import { AssociatedProductAssuredExceededModalComponent } from '@app/main/my-command-centre/Levels/product-step/product-detailed-view/associated-product-assured-exceeded/associated-product-assured-exceeded-modal.component';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        NgbModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        YodleeModalComponent,
        MyCreditModalComponent,
        MyHealthCareModalComponent,
        MyWearablesModalComponent,
        QuoteModalComponent,
        ImagePipe,
        CurrencyPipeZar,
        ThousandSeperatorPipe,
        GroupByPipe,
        FilterByPipe,
        MyFilterPipe,
        TruncateUnderscorePipe,
        RemoveAllUnderscore,
        QuestionableBooleanPipe,
        ActionModalComponent,
        YearsPipe,
        TruncateDecimalPipe,
        NumberDirective,
        ArraySortPipe,
        DeclineModalComponent,
        SupportModalComponent,
        CustomDatePickerComponent,
        SupportFloaterComponent,
        ConfirmProductPremiumModalComponent,
        AssociatedProductAssuredExceededModalComponent
    ],
    exports: [
        NumberDirective,
        ImagePipe,
        CurrencyPipeZar,
        ArraySortPipe,
        ThousandSeperatorPipe,
        GroupByPipe,
        FilterByPipe,
        MyFilterPipe,
        TruncateUnderscorePipe,
        RemoveAllUnderscore,
        YearsPipe,
        TruncateDecimalPipe,
        YodleeModalComponent,
        MyCreditModalComponent,
        MyHealthCareModalComponent,
        MyWearablesModalComponent,
        QuoteModalComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        NgbModule,
        FormsModule,
        TruncateDecimalPipe,
        QuestionableBooleanPipe,
        ActionModalComponent,
        CustomDatePickerComponent,
        SupportFloaterComponent
    ],
    providers: [
        ArraySortPipe,
        CurrencyPipeZar,
        FilterByPipe,
        GroupByPipe,
        TruncateUnderscorePipe,
        RemoveAllUnderscore,
        YearsPipe,
        TruncateDecimalPipe,
        ThousandSeperatorPipe,
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,

    ], entryComponents: [ActionModalComponent, DeclineModalComponent, SupportModalComponent, ConfirmProductPremiumModalComponent, AssociatedProductAssuredExceededModalComponent]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard,
            ]
        };
    }
}
