/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-uploader.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/button/button.ngfactory";
import * as i3 from "primeng/components/button/button";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i6 from "primeng/components/fileupload/fileupload";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common/http";
import * as i9 from "primeng/components/common/shared";
import * as i10 from "./file-uploader.component";
import * as i11 from "./service/file-storage.service";
import * as i12 from "abp-ng2-module/dist/src/message/message.service";
import * as i13 from "../IDSVRAuthProviders/auth.service";
import * as i14 from "ngx-bootstrap/modal";
var styles_FileUploaderComponent = [i0.styles];
var RenderType_FileUploaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FileUploaderComponent, data: {} });
export { RenderType_FileUploaderComponent as RenderType_FileUploaderComponent };
function View_FileUploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "drop-message pr-4 pl-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionalHeaderText; _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "file-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "file-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "document"], ["src", "assets/common/images/documents_icon_red.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "file-name"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "file-size"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "file-clear-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p-button", [["icon", "pi pi-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Button_0, i2.RenderType_Button)), i1.ɵdid(9, 49152, null, 0, i3.Button, [], { icon: [0, "icon"], disabled: [1, "disabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "pi pi-times"; var currVal_4 = _co.isUploading; _ck(_v, 9, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.formatBytes(_v.context.$implicit.size); _ck(_v, 6, 0, currVal_2); }); }
function View_FileUploaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "drop-message"]], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "drag-drop-icon"], ["class", "drag-drop-icon"], ["src", "assets/common/images/download_red.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Drop your files here or click \"Choose\" above. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || _co.isUploading); _ck(_v, 0, 0, currVal_0); }); }
function View_FileUploaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploaderComponent_5)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v.parent, 5).files.length <= 0); _ck(_v, 1, 0, currVal_0); }, null); }
function View_FileUploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "kt-portlet__body pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [["class", "horizontal-line mt-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "p-fileUpload", [["cancelLabel", "Clear"], ["customUpload", "true"], ["mode", "advanced"], ["name", "elevate-uploader"], ["styleClass", "elv-uploader"]], null, [[null, "onProgress"], [null, "uploadHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onProgress" === en)) {
        var pd_0 = (_co.progressReport($event) !== false);
        ad = (pd_0 && ad);
    } if (("uploadHandler" === en)) {
        var pd_1 = (_co.uploadHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_FileUpload_0, i5.RenderType_FileUpload)), i1.ɵdid(5, 5423104, [[1, 4], ["uploader", 4]], 1, i6.FileUpload, [i1.ElementRef, i7.DomSanitizer, i1.NgZone, i8.HttpClient], { name: [0, "name"], multiple: [1, "multiple"], accept: [2, "accept"], maxFileSize: [3, "maxFileSize"], styleClass: [4, "styleClass"], cancelLabel: [5, "cancelLabel"], showUploadButton: [6, "showUploadButton"], showCancelButton: [7, "showCancelButton"], mode: [8, "mode"], customUpload: [9, "customUpload"] }, { onProgress: "onProgress", uploadHandler: "uploadHandler" }), i1.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_FileUploaderComponent_3)), i1.ɵdid(8, 16384, [[2, 4]], 0, i9.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_FileUploaderComponent_4)), i1.ɵdid(10, 16384, [[2, 4]], 0, i9.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasOptionalHeader(); _ck(_v, 3, 0, currVal_0); var currVal_1 = "elevate-uploader"; var currVal_2 = _co.allowMultiple; var currVal_3 = _co.accepts; var currVal_4 = _co.maxFileSize; var currVal_5 = "elv-uploader"; var currVal_6 = "Clear"; var currVal_7 = !_co.isUploading; var currVal_8 = !_co.isUploading; var currVal_9 = "advanced"; var currVal_10 = "true"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "file"; _ck(_v, 8, 0, currVal_11); var currVal_12 = "content"; _ck(_v, 10, 0, currVal_12); }, null); }
export function View_FileUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileUploader: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "kt-portlet full-height no-margin document-uploader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "kt-portlet__head kt-portlet__head--noborder d-flex flex-row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "kt-portlet__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "kt-portlet__head-title font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload your files: "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploaderComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isProcessing; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_FileUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-uploader", [], null, null, null, View_FileUploaderComponent_0, RenderType_FileUploaderComponent)), i1.ɵdid(1, 114688, null, 0, i10.FileUploaderComponent, [i11.FileStorageService, i12.MessageService, i13.AuthService, i14.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploaderComponentNgFactory = i1.ɵccf("app-file-uploader", i10.FileUploaderComponent, View_FileUploaderComponent_Host_0, { accepts: "accepts", maxFileSize: "max-file-size", disabled: "disabled", allowMultiple: "allow-multiple", uploadStrategy: "upload-strategy", storageStrategy: "storage-strategy", fileTags: "file-tags", optionalHeaderText: "optional-header-text", showSuccess: "show-success" }, { uploadFinished: "uploadFinished" }, []);
export { FileUploaderComponentNgFactory as FileUploaderComponentNgFactory };
