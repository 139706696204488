/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./elevate-me-faster-upload-modal.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/common/file-uploader/file-uploader.component.ngfactory";
import * as i3 from "../../../shared/common/file-uploader/file-uploader.component";
import * as i4 from "../../../shared/common/file-uploader/service/file-storage.service";
import * as i5 from "abp-ng2-module/dist/src/message/message.service";
import * as i6 from "../../../shared/common/IDSVRAuthProviders/auth.service";
import * as i7 from "ngx-bootstrap/modal";
import * as i8 from "./elevate-me-faster-upload-modal.component";
import * as i9 from "../../../../shared/service-proxies/service-proxies";
var styles_ElevateMeFasterUploadModalComponent = [i0.styles];
var RenderType_ElevateMeFasterUploadModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ElevateMeFasterUploadModalComponent, data: {} });
export { RenderType_ElevateMeFasterUploadModalComponent as RenderType_ElevateMeFasterUploadModalComponent };
export function View_ElevateMeFasterUploadModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileUploader: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-file-uploader", [], null, [[null, "uploadFinished"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadFinished" === en)) {
        var pd_0 = (_co.onUploadFinished($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FileUploaderComponent_0, i2.RenderType_FileUploaderComponent)), i1.ɵdid(3, 114688, [[1, 4], ["fileUploader", 4]], 0, i3.FileUploaderComponent, [i4.FileStorageService, i5.MessageService, i6.AuthService, i7.BsModalRef], { storageStrategy: [0, "storageStrategy"] }, { uploadFinished: "uploadFinished" })], function (_ck, _v) { var currVal_0 = 5; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ElevateMeFasterUploadModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elevate-me-faster-upload-modal", [], null, null, null, View_ElevateMeFasterUploadModalComponent_0, RenderType_ElevateMeFasterUploadModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ElevateMeFasterUploadModalComponent, [i1.Injector, i9.UploadServiceProxy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElevateMeFasterUploadModalComponentNgFactory = i1.ɵccf("elevate-me-faster-upload-modal", i8.ElevateMeFasterUploadModalComponent, View_ElevateMeFasterUploadModalComponent_Host_0, {}, { onUploadComplete: "onUploadComplete" }, []);
export { ElevateMeFasterUploadModalComponentNgFactory as ElevateMeFasterUploadModalComponentNgFactory };
