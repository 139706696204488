import { HttpEvent } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { FileUploadResponse } from '@app/shared/common/file-uploader/models/file-upload-response';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ClaimModifyServiceProxy, ModifyClaimDto, UploadServiceProxy, UserClaimEditDto, YodleeServiceProxy } from '@shared/service-proxies/service-proxies';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { TruIDModalComponent } from '../truID-modal/truID-modal.component';
import { AppConsts } from '@shared/AppConsts';
import * as moment from 'moment';
import { NotificationSignalRService } from '@shared/NotificationSignalrService/notification-signal-r.service';
import {MessageService} from '@abp/message/message.service';

@Component({
    selector: 'truID-upload-modal',
    templateUrl: './truID-upload-modal.component.html',
    styleUrls: ['./truID-upload-modal.component.css']
})
export class TruIDUploadModalComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    @Output() onProcessingComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    selectedFile: File;
    selectedProvider: string;
    isProcessing: boolean;
    countdown = 0;
    timeoutError = false;
    constructor(
        injector: Injector,
        private uploadService: UploadServiceProxy,
        public _financialService: MyFinancialService,
        private _yodleeAppService: YodleeServiceProxy,
        private _claimModify: ClaimModifyServiceProxy,
        private _notificationSignalRService: NotificationSignalRService,
        private msgService: MessageService,
    ) {
        super(injector);
    }

    ngOnInit() {
        // this._notificationSignalRService.triggerIsProcessing(this.setIsProcessing.bind(this));
        this._notificationSignalRService.processingNotification$.subscribe(x => this.setIsProcessing());
    }

    ngAfterViewInit() {
        const endOfLastMonth = moment().subtract(1, 'months').endOf('month').format('MMMM D, YYYY');
        this.fileUploader.optionalHeaderText = `Please ensure that you upload your latest account statements up to the end of ${endOfLastMonth} for all accounts your want reflected.`;
        this.fileUploader.showSuccess = false;
    }

    fileSelected(event: File) {
        this.selectedFile = event;
        this.fileUploader.showSuccess = false;
        console.log(this.selectedProvider);
    }

    setIsProcessing(): void {
        if (this.isProcessing) {
            this.isProcessing = false;
            this.msgService.success('Please check in My Financials that your latest data reflects. If you have any issues, please contact support@elevate.co.za');
            this.onProcessingComplete.next(true);
            return;
        }
        this.isProcessing = true;
    }

    onProviderChange(event: any) {
        this.fileUploader.fileTags = [this.selectedProvider];
        console.log('Provider changed:', this.selectedProvider);
    }

    onUploadFinished(uploadResponse: any) {
        this.isProcessing = true;
        this.fileUploader.isProcessing = true;

        this.countdown = 180;
        const intervalId = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
                clearInterval(intervalId);
                this.isProcessing = false;
                this.fileUploader.isProcessing = false;
                this.timeoutError = true;
            }
        }, 1000);
        this._yodleeAppService.registerTruIDUserInElevateEnv(this._authService.user.access_token, uploadResponse.result[0].fileName)
                .subscribe(userRegisteredRes => {
                    this._financialService.isEntityRegistered().then(entityRes => {
                        if (this._authGuard.user.profile.allow_financial !== 'true') {
                            this._claimModify.modifyClaim(this.getInputForFinancialClaimChange())
                                .subscribe(() => {
                                    this.updateClaimsIdsvr();
                                    abp.ui.clearBusy();
                                });
                        } else {
                            abp.ui.clearBusy();
                        }
                    });
                });
    }

    getInputForFinancialClaimChange(): ModifyClaimDto {
        const claimList = new ModifyClaimDto();
        claimList.userClaimEditDtos = new Array<UserClaimEditDto>();

        const allowFinancialClaim = new UserClaimEditDto();
        allowFinancialClaim.claimType = AppConsts.ClaimTypes.allow_financial;
        allowFinancialClaim.claimValue = 'true';
        claimList.userClaimEditDtos.push(allowFinancialClaim);

        const proofOfIncomeClaim = new UserClaimEditDto();
        proofOfIncomeClaim.claimType = AppConsts.ClaimTypes.proof_of_income;
        proofOfIncomeClaim.claimValue = 'true';
        claimList.userClaimEditDtos.push(proofOfIncomeClaim);

        const proofOfIncomeDateClaim = new UserClaimEditDto();
        proofOfIncomeDateClaim.claimType = AppConsts.ClaimTypes.proof_of_income_date;
        proofOfIncomeDateClaim.claimValue = moment().format('DD/MM/YYYY hh:mm:ss A');
        claimList.userClaimEditDtos.push(proofOfIncomeDateClaim);

        claimList.userId = this._authGuard.user.profile.sub;
        claimList.accessToken = this._authService.user.access_token;

        return claimList;
    }

    getFormattedTime(seconds: number): string {
        const duration = moment.duration(seconds, 'seconds');
        const minutes = String(duration.minutes()).padStart(2, '0');
        const remainingSeconds = String(duration.seconds()).padStart(2, '0');
        return `${minutes}:${remainingSeconds}`;
    }

    close(): void {
        this.bsModalRef.hide();
    }
}
