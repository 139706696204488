import { Injector, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
//import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeUserLanguageDto, LinkedUserDto, ProfileServiceProxy, UserLinkServiceProxy, QuirkCachedServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile, User } from 'oidc-client';
import { AuthGuard } from '../common/IDSVRAuthProviders/auth.guard.service';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';
import { Router } from '@angular/router';
import { SupportModalComponent } from '../common/modals/support-modal/support-modal.component';

@Component({
    templateUrl: './topbar.component.html',
    selector: 'topbar',
    styleUrls: ['topbar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TopBarComponent extends AppComponentBase implements OnInit {

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    firstName = '';
    lastName = '';
    faebookprofilepicture = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/user.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    userInfo$: Observable<User>;
    currentUrlForChangePassword = '';
    profilePictureWhite: string;
    isDefaultProfilePicture = false;
    isLiveChatHover = false;
    private _isUserLoggedIn: boolean;


    constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        //private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private router: Router
    ) {
        super(injector);
        if (abp.setting.values['PortalSettings.authority'].lastIndexOf('/') === abp.setting.values['PortalSettings.authority'].length - 1) {
            this.currentUrlForChangePassword = abp.setting.values['PortalSettings.authority'] + 'account/changepassword';

        } else {
            this.currentUrlForChangePassword = abp.setting.values['PortalSettings.authority'] + '/account/changepassword';

        }
    }

    public get isUserLoggedIn(): boolean {
        return this._isUserLoggedIn;
    }
    public set isUserLoggedIn(value: boolean) {
        this._isUserLoggedIn = value;
    }

    ngOnInit() {
        this.isUserLoggedIn = this._authService.isLoggedIn();
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, l => (l).isDisabled === false);

        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;

        if (this.isUserLoggedIn) {
            this.setCurrentLoginInformations();
            this.registerToEvents();
            this.getProfilePicture();
        }


    }

    isExtQuote(): boolean {
        return this.router.url.indexOf('ExtQuote') > 0 || this.router.url.indexOf('ExtCalculator') > 0;
    }

    getHeaderText() {
        if (this.isExtQuote()) {
            return null;
        }

        if (this.router.url.indexOf('ExtCalculator') > 0 || this.router.url.indexOf('MyNeedsCalculator') > 0) {
            return 'MY NEEDS CALCULATOR';
        }

        return this.l('MWP');
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture(true);
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });

        abp.event.on('app.getRecentlyLinkedUsers', () => {
            this.getRecentlyLinkedUsers();
        });

        abp.event.on('app.onMySettingsModalSaved', () => {
            this.onMySettingsModalSaved();
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }

    setCurrentLoginInformations(): void {

        let abpEventTriggered = false;
        abp.event.on('updateProfileName', data => {
            abpEventTriggered = true;
            this._authService.getUser((res: Profile) => {
                this.firstName = res.given_name;
            }, true);
        });

        if (!abpEventTriggered) {
            if (this._authService) {
                this._authService.getUser((res: Profile) => {
                    this.firstName = res.given_name;
                });
            }
        }

        // this.firstName = this.authGuard.user.profile.given_name;
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }
        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }

    getProfilePicture(forceUpdate: boolean = false): void {
        this._authGuard.GetUserInfo((res: Profile) => {
            this.profilePicture = res.picture;
            this.isDefaultProfilePicture = false;

            if (this.profilePicture === undefined || this.profilePicture === null || this.profilePicture === '') {
                this.isDefaultProfilePicture = true;
                this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/user.png';
                this.profilePictureWhite = AppConsts.appBaseUrl + '/assets/common/images/user-white.png';
            }
        }, forceUpdate);
    }

    getfacebookProfilePicture(): void {
        this._profileServiceProxy.getFacebookFeeds().subscribe(result => {
            this.profilePicture = result.url;
        });
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }


    showLoginAttempts(): void {
        abp.event.trigger('app.show.loginAttemptsModal');
    }

    showLinkedAccounts(): void {
        abp.event.trigger('app.show.linkedAccountsModal');
    }

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }


    changeMySettings(): void {

        abp.event.trigger('app.show.mySettingsModal');
    }

    logout(): void {
        this._authService.startLogout();
        //this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        //this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    get chatEnabled(): boolean {
        return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }

    openpopup() {
        this.configModal.class = 'mw-400px mt-48';
        this.configModal.ignoreBackdropClick = false;
        this.bsModalRef = this.modalService.show(SupportModalComponent, this.configModal);
    }

    getClr() {
        return '#4fc2c0 !important';
    }
}
