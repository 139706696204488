import { AfterViewInit, Component, Injector, OnInit, ViewChild, ErrorHandler, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/AppEnums';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment/moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { AzureInsightsService } from '@shared/AzureLogging/azure-insights.service';
import { NotificationSignalRService } from '@shared/NotificationSignalrService/notification-signal-r.service';
import { QuirkService } from './shared/services/quirk-api-service-proxy.service';
import { MyCommandCenterService } from './main/my-command-centre/my-command-center.service';
import 'shared/extensions/common-extensions.component';
import { PolicyService } from './shared/services/policy.service';
import { ClaimModifyServiceProxy } from '@shared/service-proxies/service-proxies';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare let fbq: Function;

export const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

export function changeTemplatePopupGlobal(item, item1) {
    if (item !== undefined) {
        (document.getElementsByClassName('swal2-confirm')[0] as any).innerText = item;
        document.getElementsByClassName('swal2-confirm')[0].classList.add('modal-button-clr');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-confirm')[0].classList.add('font-size-12');
    } else {
        document.getElementsByClassName('swal2-confirm')[0].classList.add('d-none');
    }

    if (item1 !== undefined) {
        (document.getElementsByClassName('swal2-cancel')[0] as any).innerText = item1;
        document.getElementsByClassName('swal2-cancel')[0].classList.add('border-radius-55');
        document.getElementsByClassName('swal2-cancel')[0].classList.add('font-size-12');
    } else {
        document.getElementsByClassName('swal2-cancel')[0].classList.add('d-none');
    }
}

export function isMobileView_NewGlobal() {
    if (window.innerWidth < 768) {
        return true;
    } else {
        return false;
    }
}


@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;
    routerSub: Subscription;

    @ViewChild('loginAttemptsModal') loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal') changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal') changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal') mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal') notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent') chatBarComponent;

    public constructor(
        injector: Injector,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper,
        private myMonitoringService: AzureInsightsService,
        private _notificationChannel: NotificationSignalRService,
        private quirkService: QuirkService,
        private commandService: MyCommandCenterService,
        private _policyService: PolicyService,
        private _claimModifyService: ClaimModifyServiceProxy,
        private router: Router
    ) {
        super(injector);
        this.myMonitoringService.logPageView();
    }

    ngOnInit(): void {
        if (isIEOrEdge) {
            this.showIfIE();
        }

        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.registerModalOpenEvents();
        //if (this.appSession.application) {
        //    SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        //}

        this.getPersonAndUpdatePolicyUserClaim();
    }

    ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    showIfIE() {
        (window as any).cookieconsent.initialise({
            'palette': {
                'popup': {
                    'background': '#3937a3'
                },
                'button': {
                    'background': '#e62576'
                }
            },
            'showLink': false,
            'content': {
                'message': 'Your web browser (Internet Explorer) is not fully compatible. Use different browser for more security, speed and the best experience on this site.',
                'dismiss': 'OKAY'
            }
        });
    }

    getPersonAndUpdatePolicyUserClaim() {
        if (sessionStorage.getItem('set_policy_user_status') !== '1') {
            this._policyService.getPerson(this._authGuard.user.profile.sub, false).subscribe(result => {
                sessionStorage.setItem('set_policy_user_status', '1');

                let hasActiveCover = false;

                if (result && result.CoverInstanceAPIs) {
                    for (const cover of result.CoverInstanceAPIs) {
                        if (cover.CoverStatus === 'Active') {
                            hasActiveCover = true;
                            break;
                        }
                    }

                    if (hasActiveCover && !this._authService.userPermission.includes('PolicyUser1')) {
                        this._claimModifyService.addUserRole(this._authService.user.access_token, 'PolicyUser1').subscribe(result => {
                            this._authService.getUserRoleViaAjax(() => { });
                        });
                    } else if (!hasActiveCover && this._authService.userPermission.includes('PolicyUser1')) {
                        this._claimModifyService.deleteUserRole(this._authService.user.access_token, 'PolicyUser1').subscribe(result => {
                            this._authService.getUserRoleViaAjax(() => { });
                        });
                    }
                }
            });
        }
    }

    ngAfterViewInit(): void {
        // abp.signalr.autoConnect = false;
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }
}



export class MyErrorHandler implements ErrorHandler {
    handleError(error) {
        abp.message.error(error);
        abp.ui.clearBusy();
    }
}

