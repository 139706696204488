import { Injectable } from '@angular/core';
import { RoutingArray } from '@app/main/my-financials/my-financial-breadcrumb/my-financial-breadcrumb.component';
import { MyFinancialServiceProxy, GetMyFundsDetailsOutput, MyNetWorthOutput, TransactionHistoryOuput, TransactionCategoryOutputDto, AccountSummaryOutput, AccountSummaryOutputWithType, DropDownOutputDto, AccountDropdownDetails, ChartOuptutDto, YodleeServiceProxy, LastFinancialDateDto, AccountLastUpdatedDate } from '@shared/service-proxies/service-proxies';
import { CommonhelperService } from './commonhelper.service';
import * as moment from 'moment/moment';
import { MessageService } from '@app/main/my-financials/financials.service';
import { forkJoin, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../common/IDSVRAuthProviders/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MyFinancialService {

    public breadCrumbsDetail = new Array<RoutingArray>(3);
    fundDetails: GetMyFundsDetailsOutput[] = new Array<GetMyFundsDetailsOutput>();
    netWorthOuputDetails: MyNetWorthOutput = new MyNetWorthOutput();
    transactionHistoryOuput: TransactionHistoryOuput[] = new Array<TransactionHistoryOuput>();
    transactionCategoryOutputDtofront: TransactionHistoryOuput[] = new Array<TransactionHistoryOuput>();
    transactionCategoryOutputDto: TransactionCategoryOutputDto[] = new Array<TransactionCategoryOutputDto>();
    accountSummaryOutput: AccountSummaryOutputWithType[] = new Array<AccountSummaryOutputWithType>();
    pageNo = 0;
    dropDownOutput: DropDownOutputDto;
    startDate: Date = null;
    endDate: Date = null;
    accountLastUpdatedDates: AccountLastUpdatedDate[] = new Array<AccountLastUpdatedDate>();
    lastUpdatedDate: LastFinancialDateDto = null;
    graphData: ChartOuptutDto[] = new Array<ChartOuptutDto>();
    totalSum = 0;
    latestUpdateFinancialDataDate: string;
    fetchingRecords = false;
    hasFinishedGetAll: boolean;
    truIdSuccess: boolean;
    hasOldProviders: boolean;

    constructor(
        private messageService: MessageService,
        private _financialServiceProxy: MyFinancialServiceProxy,
        private _yodleeAppService: YodleeServiceProxy,
        private _commonService: CommonhelperService,
        private _authService: AuthService
    ) {
        this.breadCrumbsDetail = [{
            routePath: '/app/main/dashboard',
            routeTitle: 'MY DATA'
        },
        {
            routePath: '/app/main/MyFinancials',
            routeTitle: 'MY FINANCES'
        }];
        this.dropDownOutput = new DropDownOutputDto();
        this.dropDownOutput.accountType = new Array<string>();
        this.dropDownOutput.accountDropdownDetails = new Array<string>();
        this.dropDownOutput.fullDetails = new Array<AccountDropdownDetails>();

    }

    isMyFinancialactivated(): boolean {
        return this._commonService.myFinancialactivated;
    }

    getAllData(startDate?: any, endDate?: any, lastUpdatedDateList?: any) {
        this.hasFinishedGetAll = false;
        let startDateJson = JSON.parse(JSON.stringify(startDate));
        let endDateJson = JSON.parse(JSON.stringify(endDate));

        this.getFunds(startDateJson, endDateJson).subscribe(res => {
            this.fundDetails = res.length > 0 ? res : this.fundDetails;
        }, (err) => {
            console.error(err);
        }, () => {
            this.messageService.sendMessage('getFunds');
        });

        this.getMyAccountSummary(startDateJson, endDateJson, lastUpdatedDateList)
            .subscribe(res => {
                this.accountSummaryOutput = res.length > 0 ? res : this.accountSummaryOutput;

                let accountIds: number[] = [];
                this.accountSummaryOutput.forEach(y => y.accountSummaryOutputs.forEach(x => accountIds.push(x.accountId)));
                this.accountSummaryOutput.forEach(y => y.accountSummaryOutputs.forEach(x => x.showDelete = true));
                this.accountSummaryOutput.forEach(y => y.accountSummaryOutputs.forEach(x => x.showConfirm = false));

                this.getMyTransaction(startDateJson, endDateJson, accountIds).subscribe(res => {
                    this.transactionHistoryOuput = res.length > 0 ? res : this.transactionHistoryOuput;
                    this.transactionCategoryOutputDtofront = res.length > 0 ? res : this.transactionCategoryOutputDtofront;
                }, (err) => {
                    console.error(err);
                }, () => {
                    this.messageService.sendMessage('getMyTransaction');
                });
            }, (err) => {
                console.error(err);
            }, () => {
                this.messageService.sendMessage('getMyAccountSummary');
            });

        this.getMyTransactionCategories(startDateJson, endDateJson)

            .subscribe(res => {
                this.transactionCategoryOutputDto = res.length > 0 ? res : this.transactionCategoryOutputDto;
                this.totalSum = 0;
                this.transactionCategoryOutputDto.forEach(res => {
                    this.totalSum += Math.abs(Math.round(res.amount));
                });
            }, (err) => {
                console.error(err);
            }, () => {
                this.messageService.sendMessage('getMyTransactionCategories');
            });
    }

    getFunds(startDate?: Date, endDate?: any): Observable<GetMyFundsDetailsOutput[]> {
        return this._financialServiceProxy.getMyFunds(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token).pipe(finalize(() => { this.fetchingRecords = false; }));
    }

    getGraphData(startDate?: any, endDate?: any, accountId?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._financialServiceProxy.getGranulDataForGraph(
                moment(startDate),
                moment(endDate),
                this._authService.user.access_token,
                accountId).pipe(finalize(() => { this.fetchingRecords = false; }))
                .subscribe((res: ChartOuptutDto[]) => {
                    this.graphData = res;
                    this.messageService.sendMessage('getGraphData');
                    abp.ui.clearBusy();
                    resolve(null);
                }, (err) => {
                    reject(null);
                    abp.ui.clearBusy();
                });
        });
    }

    isEntityRegistered(): Promise<boolean> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.isEntityRegistered(this._authService.user.access_token)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    this._commonService.myFinancialactivated = res;
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });


    }

    getMyNetWorth(startDate?: Date, endDate?: any, lastUpdatedDateList?: any): Observable<MyNetWorthOutput> {
        return this._financialServiceProxy.getMyNetWorth(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token,
            lastUpdatedDateList).pipe(finalize(() => { this.fetchingRecords = false; }));
    }

    getMyTransaction(startDate?: any, endDate?: any, accountIds?): Observable<TransactionHistoryOuput[]> {
        return this._financialServiceProxy.getMyRecentTransactions(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token,
            accountIds).pipe(finalize(() => { this.fetchingRecords = false; }));
    }

    getMyTransactionFilter(startDate?: any, endDate?: any, accountId?) {
        this._financialServiceProxy.getMyRecentTransactions(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token,
            accountId).pipe(finalize(() => { this.fetchingRecords = false; }))
            .subscribe((res: TransactionHistoryOuput[]) => {
                this.transactionHistoryOuput = res;
            }, (err) => {
            });
    }

    getMyLatestUpdatedFinancialDataDate(): Observable<LastFinancialDateDto> {
        return this._financialServiceProxy.getMyLastUpdatedFinancialDataDate(
            this._authService.user.access_token).pipe(
                finalize(() => { this.fetchingRecords = false; }),
                tap((val) => this.lastUpdatedDate = val)
            );
    }

    queueFinancialDataSync(): Promise<boolean> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.queueFinancialDataSync(this._authService.user.access_token)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }

    queueTruIdFinancialDataSync(collectionId: string): Promise<boolean> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.queueTruIdFinancialDataSync(this._authService.user.access_token, collectionId)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }

    getMyTransactionCategories(startDate?: any, endDate?: any): Observable<TransactionCategoryOutputDto[]> {
        return this._financialServiceProxy.getSpendingCategories(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token).pipe(finalize(() => { this.fetchingRecords = false; }));
    }

    getMyAccountSummary(startDate?: Date, endDate?: any, lastUpdatedDateList?: any): Observable<AccountSummaryOutputWithType[]> {
        return this._financialServiceProxy.getMyAccountSummary(
            moment(startDate),
            moment(endDate),
            this._authService.user.access_token,
            lastUpdatedDateList).pipe(finalize(() => { this.fetchingRecords = false; }));
    }

    getFilterDropDownForTransaction() {
        this._financialServiceProxy.getFilterForTransaction(this._authService.user.access_token).pipe(finalize(() => { this.fetchingRecords = false; }))
            .subscribe((res: DropDownOutputDto) => {
                this.dropDownOutput = res;
            }, (err) => {
            });
    }

    deleteTruIdAccount(providerCode: string, accountId: string): Promise<string> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.deleteTruIdAccount(this._authService.user.access_token, providerCode, accountId)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }

    deleteAccount(accountId: number): Promise<string> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.deleteAccount(this._authService.user.access_token, accountId)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }

    syncAccounts(providerAccountId: string): Promise<string> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.getAllAccountYodlee(this._authService.user.access_token, providerAccountId, '')
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }

    syncYodleeData(): Promise<string> {
        return new Promise((resovle, reject) => {
            this._yodleeAppService.getAllDataYodlee(this._authService.user.access_token)
                .pipe(finalize(() => { this.fetchingRecords = false; })).subscribe(res => {
                    return resovle(res);
                }, (err) => {
                    return reject(false);
                });
        });
    }
}
