import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { HubConnectionState } from '@aspnet/signalr/dist/esm/HubConnection';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
const WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS = 2000;

@Injectable({
    providedIn: 'root'
})
export class NotificationSignalRService {
    messageReceived$ = new Subject<string>();
    connectionEstablished$ = new BehaviorSubject<boolean>(false);
    notificationData$ = new Subject();
    processingNotification$ = new Subject();
    singalrNotification = abp.setting.get('ServerRootAddress').indexOf('/') === abp.setting.get('ServerRootAddress').length - 1 ? 'signalr-notification' : '/signalr-notification';
    private syncFunc: () => void;
    // private processingFunc: () => void;
    private setIsOnBankScreenFunc: () => void;
    private hubConnection: HubConnection;

    constructor(private authService: AuthService, public _myFinancialService: MyFinancialService) {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }


    private createConnection() {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(abp.setting.get('ServerRootAddress') + this.singalrNotification, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    private startConnection(attempt: number = 1) {
        const MAX_ATTEMPTS = 50;
    
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            return;
        }
    
        if (attempt > MAX_ATTEMPTS) {
            console.error('Failed to start the hub connection after ' + MAX_ATTEMPTS + ' attempts.');
            return;
        }
    
        setTimeout(() => {
            this.hubConnection.start().then(
                () => {
                    console.log('Hub connection started');
                    this.hubConnection.invoke('onConnectedAsync', this.authService.user.profile.sub).then(() => {
                        console.log('Connected async');
                        // Send the connectionId to controller
                    });
                    this.connectionEstablished$.next(true);
                },
                error => {
                    console.error(error);
                    console.log(`Attempt ${attempt} failed. Retrying...`);
                    this.startConnection(attempt + 1);
                }
            );
        }, WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS + (attempt - 1) * 1000); // Incremental backoff
    }

    private registerOnServerEvents(): void {
        this.hubConnection.on('NotificationReceived', (data: any) => {
            if (data[0].includes('collectionId')) {
                let collectionId = data[0].split(':')[1];
                this._myFinancialService.truIdSuccess = true;
                this._myFinancialService.queueTruIdFinancialDataSync(collectionId.trim()).then((result) => {
                    this.syncFunc();
                    this.processingNotification$.next(true);
                });
            }
            console.log(data);
            this.notificationData$.next(data);
        });
        this.hubConnection.on('SelectingProvider', (data: any) => {
            if (data[0].includes('collectionId')) {
                let collectionId = data[0].split(':')[1];
                this.setIsOnBankScreenFunc();
            }
            console.log(data);
            this.notificationData$.next(data);
        });
        this.hubConnection.on('ChosenProvider', (data: any) => {
            if (data[0].includes('collectionId')) {
                let collectionId = data[0].split(':')[1];
                this.setIsOnBankScreenFunc();
            }
            console.log(data);
            this.notificationData$.next(data);
        });
        this.hubConnection.onclose(() => {
            console.log('Hub connection disconnected');
            this.startConnection();
        });
        }

        syncTruIdData(fn: () => void) {
            this.syncFunc = fn;
        }

        setIsOnBankScreen(fn: () => void) {
            this.setIsOnBankScreenFunc = fn;
    }
}
