var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, EventEmitter, Injector, OnInit } from '@angular/core';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ClaimModifyServiceProxy, ModifyClaimDto, UploadServiceProxy, UserClaimEditDto, YodleeServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import * as moment from 'moment';
import { NotificationSignalRService } from '@shared/NotificationSignalrService/notification-signal-r.service';
import { MessageService } from '@abp/message/message.service';
var TruIDUploadModalComponent = /** @class */ (function (_super) {
    __extends(TruIDUploadModalComponent, _super);
    function TruIDUploadModalComponent(injector, uploadService, _financialService, _yodleeAppService, _claimModify, _notificationSignalRService, msgService) {
        var _this = _super.call(this, injector) || this;
        _this.uploadService = uploadService;
        _this._financialService = _financialService;
        _this._yodleeAppService = _yodleeAppService;
        _this._claimModify = _claimModify;
        _this._notificationSignalRService = _notificationSignalRService;
        _this.msgService = msgService;
        _this.onProcessingComplete = new EventEmitter();
        _this.countdown = 0;
        _this.timeoutError = false;
        return _this;
    }
    TruIDUploadModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this._notificationSignalRService.triggerIsProcessing(this.setIsProcessing.bind(this));
        this._notificationSignalRService.processingNotification$.subscribe(function (x) { return _this.setIsProcessing(); });
    };
    TruIDUploadModalComponent.prototype.ngAfterViewInit = function () {
        var endOfLastMonth = moment().subtract(1, 'months').endOf('month').format('MMMM D, YYYY');
        this.fileUploader.optionalHeaderText = "Please ensure that you upload your latest account statements up to the end of " + endOfLastMonth + " for all accounts your want reflected.";
        this.fileUploader.showSuccess = false;
    };
    TruIDUploadModalComponent.prototype.fileSelected = function (event) {
        this.selectedFile = event;
        this.fileUploader.showSuccess = false;
        console.log(this.selectedProvider);
    };
    TruIDUploadModalComponent.prototype.setIsProcessing = function () {
        if (this.isProcessing) {
            this.isProcessing = false;
            this.msgService.success('Please check in My Financials that your latest data reflects. If you have any issues, please contact support@elevate.co.za');
            this.onProcessingComplete.next(true);
            return;
        }
        this.isProcessing = true;
    };
    TruIDUploadModalComponent.prototype.onProviderChange = function (event) {
        this.fileUploader.fileTags = [this.selectedProvider];
        console.log('Provider changed:', this.selectedProvider);
    };
    TruIDUploadModalComponent.prototype.onUploadFinished = function (uploadResponse) {
        var _this = this;
        this.isProcessing = true;
        this.fileUploader.isProcessing = true;
        this.countdown = 180;
        var intervalId = setInterval(function () {
            _this.countdown--;
            if (_this.countdown <= 0) {
                clearInterval(intervalId);
                _this.isProcessing = false;
                _this.fileUploader.isProcessing = false;
                _this.timeoutError = true;
            }
        }, 1000);
        this._yodleeAppService.registerTruIDUserInElevateEnv(this._authService.user.access_token, uploadResponse.result[0].fileName)
            .subscribe(function (userRegisteredRes) {
            _this._financialService.isEntityRegistered().then(function (entityRes) {
                if (_this._authGuard.user.profile.allow_financial !== 'true') {
                    _this._claimModify.modifyClaim(_this.getInputForFinancialClaimChange())
                        .subscribe(function () {
                        _this.updateClaimsIdsvr();
                        abp.ui.clearBusy();
                    });
                }
                else {
                    abp.ui.clearBusy();
                }
            });
        });
    };
    TruIDUploadModalComponent.prototype.getInputForFinancialClaimChange = function () {
        var claimList = new ModifyClaimDto();
        claimList.userClaimEditDtos = new Array();
        var allowFinancialClaim = new UserClaimEditDto();
        allowFinancialClaim.claimType = AppConsts.ClaimTypes.allow_financial;
        allowFinancialClaim.claimValue = 'true';
        claimList.userClaimEditDtos.push(allowFinancialClaim);
        var proofOfIncomeClaim = new UserClaimEditDto();
        proofOfIncomeClaim.claimType = AppConsts.ClaimTypes.proof_of_income;
        proofOfIncomeClaim.claimValue = 'true';
        claimList.userClaimEditDtos.push(proofOfIncomeClaim);
        var proofOfIncomeDateClaim = new UserClaimEditDto();
        proofOfIncomeDateClaim.claimType = AppConsts.ClaimTypes.proof_of_income_date;
        proofOfIncomeDateClaim.claimValue = moment().format('DD/MM/YYYY hh:mm:ss A');
        claimList.userClaimEditDtos.push(proofOfIncomeDateClaim);
        claimList.userId = this._authGuard.user.profile.sub;
        claimList.accessToken = this._authService.user.access_token;
        return claimList;
    };
    TruIDUploadModalComponent.prototype.getFormattedTime = function (seconds) {
        var duration = moment.duration(seconds, 'seconds');
        var minutes = String(duration.minutes()).padStart(2, '0');
        var remainingSeconds = String(duration.seconds()).padStart(2, '0');
        return minutes + ":" + remainingSeconds;
    };
    TruIDUploadModalComponent.prototype.close = function () {
        this.bsModalRef.hide();
    };
    return TruIDUploadModalComponent;
}(AppComponentBase));
export { TruIDUploadModalComponent };
