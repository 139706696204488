var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, OnInit } from '@angular/core';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UploadServiceProxy } from '@shared/service-proxies/service-proxies';
var ElevateMeFasterUploadModalComponent = /** @class */ (function (_super) {
    __extends(ElevateMeFasterUploadModalComponent, _super);
    function ElevateMeFasterUploadModalComponent(injector, uploadService) {
        var _this = _super.call(this, injector) || this;
        _this.uploadService = uploadService;
        _this.onUploadComplete = new EventEmitter();
        return _this;
    }
    ElevateMeFasterUploadModalComponent.prototype.ngOnInit = function () {
    };
    ElevateMeFasterUploadModalComponent.prototype.fileSelected = function (event) {
        this.selectedFile = event;
    };
    ElevateMeFasterUploadModalComponent.prototype.onUploadFinished = function (uploadResponse) {
        var message = 'A user has uploaded documents for their Elevate Me Faster goal.\n\n' +
            'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
            'Name: ' + this._authGuard.user.profile.given_name + '\n' +
            'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
            'Email: ' + this._authGuard.user.profile.email + '\n' +
            'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
            'Document: ' + uploadResponse.result[0].url + '\n\n' +
            'Please review the documentation and update their claims accordingly.';
        this._emailService.sendGenericMail('Elevate Me Faster goal documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name, message, 'bradley.deklerk@elevate.co.za').subscribe(function () {
            console.log('Elevate Me Faster goal documentation upload email sent');
        });
        this.onUploadComplete.next(true);
    };
    return ElevateMeFasterUploadModalComponent;
}(AppComponentBase));
export { ElevateMeFasterUploadModalComponent };
