<div class="mb-4">
    <app-file-uploader #fileUploader [storage-strategy]="4" (uploadFinished)="onUploadFinished($event)" (showSuccess)="false"></app-file-uploader>
    <div *ngIf="isProcessing">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="flex-row">
                <span>
                    Processing your bank statement...
                <i class="fa fa-circle-notch fa-spin ml-2"></i>
                </span>
            </div>
            <div class="flex-row" style="height: 20px;"></div>
            <div class="flex-row" *ngIf="countdown > 0">
                <!-- Stopwatch icon and countdown timer -->
                <i class="fas fa-stopwatch"></i>
                Time remaining: {{getFormattedTime(countdown)}}
            </div>
        </div>
    </div>
    <div *ngIf="timeoutError && !isProcessing" style="padding-left: 8rem; color: red;">
        An error occurred: Please restart the upload.
    </div>
</div>