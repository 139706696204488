import { Injectable } from '@angular/core';

import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, NextObserver } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { ClaimModifyServiceProxy } from '@shared/service-proxies/service-proxies';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private manager = new UserManager(getClientSettings());
    public user: User = null;
    public urltor = '';
    userPermission: string[];
    private pathName = '/app/main/dashboard';

    constructor(private http: HttpClient, private router: Router) {
        this.manager.events.addUserLoaded(user => {
            if (!this.router.url.includes('ExtQuote') && !this.router.url.includes('ExtCalculator')) {
                this.setUser(user);
            }
        });
    }

    private setUser(user: any): void {
        this.user = user as any;

        if (!this.userPermission && this.user && !this.user.expired) {
            this.getUserRoleViaAjax(() => { });
        }
    }

    getUserStatus(callback) {
        this.manager.getUser().then(user => {
            this.setUser(user);

            // callback();
            if (!this.user) {
                this.startAuthentication();
            }
            if (this.user.expired) {
                this.startAuthentication();
            }
        });
    }


    private getDevRequestHeaders() {
        return {
            //'Authorization': 'Bearer ' + this.user.access_token,
            'Accept': 'application/json'
        };
    }

    private getProdRequestHeaders() {
        return {
            'Authorization': 'Bearer ' + this.user.access_token,
            'Accept': 'application/json'
        };
    }

    getDevUrl() {
        let url_ = AppConsts.remoteServiceBaseUrl + '/api/services/app/ClaimModify/GetUserRoleAsync?';
        if (this.user && this.user.access_token !== undefined) {
            url_ += 'accessToken=' + encodeURIComponent('' + this.user.access_token) + '&';
        }
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
    getProdUrl() {
        let url_ = abp.setting.values['PortalSettings.authority'] + 'api/UserClaim/GetCurrentUserRole';
        return url_;
    }

    getUserRoleViaAjax(callback) {
        console.log('getUserRoleViaAjax');
        let requestHeaders = {};
        let url_ = '';
        requestHeaders = this.getProdRequestHeaders();
        url_ = this.getProdUrl();

        XmlHttpRequestHelper.ajax(
            'GET',
            url_,
            requestHeaders,
            null,
            (response) => {
                this.userPermission = response;
                let route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';
                this.router.navigate([route]);
                callback();
            }
        );
    }


    getUser(callback: any, forceUpdate: boolean = false): void {
        if (this.user != null && !this.user.expired && (forceUpdate || !this.user.profile)) {
            this.http.get<User>(
                abp.setting.values['PortalSettings.authority'] + `connect/userinfo`
                , {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.user.access_token
                    })
                }).subscribe(res => {
                    this.user.profile = res as any;
                    callback(res);
                }, (err) => {
                    abp.ui.clearBusy();
                });
        } else if (this.user && this.user.profile) {
            callback(this.user.profile);
        }
    }

    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    destroySession() {
        this.http.get(abp.setting.values['PortalSettings.authority'] + `connect/endsession?id_token_hint=` + this.user.id_token);
    }

    getClaims(): any {
        return this.user;
    }

    getAuthorizationHeaderValue(): string {
        return `${this.user.token_type} ${this.user.access_token}`;
    }

    startAuthentication(pathname?): Promise<void> {
        return this.manager.signinRedirect({ state: pathname });
    }

    startLogout(): void {
        this.destroySession();
        sessionStorage.removeItem('set_policy_user_status');
        this.manager.signoutRedirect();
    }

    completeAuthentication(): Promise<void> {
        console.log('completeAuthentication');
        return this.manager.signinRedirectCallback().then(user => {
            console.log('signinRedirectCallback');
            this.user = user;
            if (user.state && (user.state !== '/auth-callback' && user.state !== '/')) {
                this.pathName = user.state;
            }
            this.getUserRoleViaAjax(() => { });
        }).catch((err) => {
            console.log('complete auth failure');
            console.log(err);
            let route = localStorage.getItem('nextLink') !== '/' && localStorage.getItem('nextLink') !== '/auth-callback' && localStorage.getItem('nextLink') !== 'check-auth' ? localStorage.getItem('nextLink') : '/app/main/dashboard';

            this.startAuthentication(route);
        });
    }
}

export function getClientSettings(): UserManagerSettings {
    return {
        authority: abp.setting.values['PortalSettings.authority'],
        client_id: abp.setting.values['PortalSettings.ClientId'],
        redirect_uri: abp.setting.values['PortalSettings.RedirectUri'],
        post_logout_redirect_uri: abp.setting.values['PortalSettings.PostLogoutRedirectUri'],
        response_type: 'code',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        scope: abp.setting.values['PortalSettings.scope'],
        silent_redirect_uri: abp.setting.values['ClientRootAddress'].lastIndexOf('/') === abp.setting.values['ClientRootAddress'].length - 1 ? abp.setting.values['ClientRootAddress'] + 'renew-callback.html' : abp.setting.values['ClientRootAddress'] + '/renew-callback.html',
        revokeAccessTokenOnSignout: true,
        monitorSession: true,
    };
}
