var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ElementRef, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { CurrencyPipeZar } from '@shared/common/pipes/commonElevate.pipe';
var InputFormatterDirective = /** @class */ (function () {
    function InputFormatterDirective(elementRef, currencyPipe) {
        this.elementRef = elementRef;
        this.currencyPipe = currencyPipe;
        this.allowedKeycodes = [
            // 0-9 on normal keyboard position.
            48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
            // . on normal keyboard position.
            190,
            // 0-9 on numpad.
            96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
            // . on numpad.
            110,
            // tab, enter & ctrl.
            9, 13, 17,
            // arrows.
            37, 38, 39, 50,
            // backspace & delete
            8, 46
        ];
        this.el = this.elementRef.nativeElement;
    }
    InputFormatterDirective.prototype.ngOnInit = function () {
        if (this.formatType === 'currency') {
            this.el.placeholder = 'R';
        }
        else {
            this.el.placeholder = 'Please enter';
        }
    };
    InputFormatterDirective.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if ((changes.changeDetectValue.currentValue || changes.changeDetectValue.currentValue === 0) && !this.hasFocus) {
            setTimeout(function () {
                _this.el.value = _this.getFormattedValue(changes.changeDetectValue.currentValue.toString());
            }, 100);
        }
    };
    InputFormatterDirective.prototype.onFocus = function (value) {
        this.hasFocus = true;
        this.el.value = value.toString().replace(/[^0-9\.]+/g, '');
    };
    InputFormatterDirective.prototype.onBlur = function (value) {
        this.hasFocus = false;
        this.el.value = this.getFormattedValue(value);
    };
    InputFormatterDirective.prototype.onKeyDown = function (event) {
        var _a;
        if (event.which === 17) {
            // Add a & c when ctrl is pressed.
            (_a = this.allowedKeycodes).push.apply(_a, __spread([65, 67]));
            return true;
        }
        if (!this.allowedKeycodes.includes(event.which)) {
            return false;
        }
    };
    InputFormatterDirective.prototype.onKeyUp = function (event) {
        if (event.which === 17) {
            this.allowedKeycodes = this.allowedKeycodes.filter(function (a) { return a !== 65 && a !== 67; });
        }
    };
    InputFormatterDirective.prototype.getFormattedValue = function (value) {
        if (value === '' || value === null || typeof value === 'undefined') {
            return '';
        }
        if (this.formatType === 'currency') {
            return this.currencyPipe.transform(+value, true);
        }
        if (this.formatType === 'age') {
            return value + " years old";
        }
        if (this.formatType === 'minutes') {
            return value + " minutes";
        }
        if (this.formatType === 'hours') {
            return value + " hours";
        }
        if (this.formatType === 'height') {
            return value + " m";
        }
        if (this.formatType === 'weight') {
            return value + " kg";
        }
        if (this.formatType === 'cm') {
            return value + " cm";
        }
        return value;
    };
    return InputFormatterDirective;
}());
export { InputFormatterDirective };
