// Metronic
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
// suppressScrollX: true
};
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ1 = NgxBootstrapDatePickerConfigService.getDatepickerConfig, ɵ2 = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig, ɵ3 = NgxBootstrapDatePickerConfigService.getDatepickerLocale;
//import { GlobalFilterMonthNavComponent } from './shared/layout/nav/global-filter-month-nav/global-filter-month-nav.component';
//import { ImagePipeActivity } from './main/my-wearables/my-wearables.component';
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
