<div class="kt-portlet full-height no-margin document-uploader-container">
    <div class="kt-portlet__head kt-portlet__head--noborder d-flex flex-row justify-content-between align-items-center">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title font-weight-bold">
               Upload your files:
            </h3>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="!isProcessing" class="kt-portlet__body pt-0">
        <hr class="horizontal-line mt-0" />
        <div *ngIf="hasOptionalHeader()" class="drop-message pr-4 pl-4">
            {{ optionalHeaderText }}
        </div>
        <p-fileUpload
                #uploader
                name="elevate-uploader"
                mode="advanced"
                customUpload="true"
                styleClass="elv-uploader"
                cancelLabel="Clear"
                [showUploadButton]="!isUploading"
                [showCancelButton]="!isUploading"
                (onProgress)="progressReport($event)"
                (uploadHandler)="uploadHandler($event)"
                [multiple]="allowMultiple"
                [accept]="accepts"
                [maxFileSize]="maxFileSize"
        >
            <ng-template let-file pTemplate="file">
                <div class="file-item">
                    <div class="file-icon">
                        <img src="assets/common/images/documents_icon_red.svg" alt="document"/>
                    </div>
                    <div class="file-name" [title]="file.name">
                        {{file.name}}
                    </div>
                    <div class="file-size">
                        {{formatBytes(file.size)}}
                    </div>
                    <div class="file-clear-btn">
                        <p-button [disabled]="isUploading" (click)="removeFile($event, file)" icon="pi pi-times"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="uploader.files.length <= 0" class="drop-message" [class.disabled]="disabled || isUploading">
                    <img alt="drag-drop-icon" class="drag-drop-icon" src="assets/common/images/download_red.svg"/>
                    Drop your files here or click "Choose" above.
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
</div>
