var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
var AppNavigationService = /** @class */ (function () {
    function AppNavigationService(_appSessionService, _authService) {
        this._appSessionService = _appSessionService;
        this._authService = _authService;
        this.rewardDefaultPageName = '/app/main/MyRewards/ElevationFund';
        if (this._authService.userPermission) {
            this.rewardDefaultPageName = this._authService.userPermission.indexOf('BasicUser1') < 0 ? '/app/main/MyRewards/OtherRewards' : this.rewardDefaultPageName;
        }
    }
    AppNavigationService.prototype.getMenu = function () {
        var self = this;
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('MY POLICIES', ['PolicyUser1'], [], ' text-left-sidebar pt-1', '../../../../assets/common/images/mypolicies.svg', '/app/main/MyPolicies', []),
            new AppMenuItem('MY DATA', ['PolicyUser1', 'FreeUser1', 'TrialUser1', 'BasicUser1'], [], 'mainmen text-left-sidebar pt-1', '../../../../assets/common/images/magnifier.svg', '/app/main/dashboard', [
                new AppMenuItem('MY WEARABLES', ['PolicyUser1', 'FreeUser1', 'TrialUser1'], [], 'wear-yellow-text', '../../../../assets/common/images/my_wearables.svg', '/app/main/MyWearables'),
                new AppMenuItem('MY HEALTHCARE', ['PolicyUser1', 'FreeUser1', 'TrialUser1'], [], 'myHealth-clr-changes-sub ', '../../../../assets/common/images/my_healthCare.svg', this._authService.userPermission.includes('FreeUser1') ? '/app/main/MyHealthCare' : '/app/main/MyHealthCare/RiskAssessment'),
                new AppMenuItem('MY CREDIT', ['PolicyUser1', 'TrialUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/my_credit.svg', '/app/main/MyCredit'),
                new AppMenuItem('MY FINANCE', ['PolicyUser1', 'TrialUser1'], [], 'myfinancial-txt-clrs', '../../../../assets/common/images/my_finance.svg', '/app/main/MyFinancials')
            ]),
            new AppMenuItem('MY GOALS', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/dart.svg', '/app/main/MyGoals'),
            new AppMenuItem('MY REWARDS', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/myReward.svg', this.rewardDefaultPageName, [
                new AppMenuItem('ELEVATE REWARDS', ['BasicUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/Elevation_Fund/Fund.svg', '/app/main/MyRewards/ElevationFund'),
                new AppMenuItem('MY MEDSHIELD REWARDS', ['FreeUser1'], [], 'mycredit-green-clrs', '../../../../assets/common/images/Medshield_Reward.svg', '/app/main/MyRewards/OtherRewards')
            ]),
            new AppMenuItem('MY POLICY MANAGEMENT', ['BasicUser1'], [], ' text-left-sidebar pt-1', '../../../../assets/common/images/mycommandcentre.svg', '/app/main/MyCommandCentre', [
                new AppMenuItem('NEW POLICY', ['BasicUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/MyPermissions'),
                new AppMenuItem('MAKE CLAIM', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/claims'),
                //new AppMenuItem('EDIT POLICY', 'PolicyUser1', 'cmd-text-clr', '../../../../assets/common/images/ADD_A_NEW_POLICY.svg', '/app/main/MyCommandCentre/editpolicy'),
                //new AppMenuItem('ADD-ON BENEFITS', 'PolicyUser1', 'cmd-text-clr', '../../../../assets/common/images/ACCESS ADDITIONAL1.svg', ''),
                new AppMenuItem('BILLING INFO', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/CHANGE BILLING.svg', '/app/main/MyCommandCentre/banking'),
                new AppMenuItem('BENEFICIARIES', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/CHANGE BENEFICIARY.svg', '/app/main/MyCommandCentre/beneficiary')
            ]),
            //new AppMenuItem('MY BILLING', 'PolicyUser1', 'text-left-sidebar pt-1', '../../../../assets/common/images/my_billing_info.svg', '/app/main/MyBillingInfo'),
            new AppMenuItem('MY RESOURCE CENTRE', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/MyResourceCentre/MY_RESOURCE_CENTRE.svg', '/app/main/MyResourceCentre', [
                new AppMenuItem('MY LIBRARY', ['PolicyUser1'], [], 'cmd-text-clr', '../../../../assets/common/images/MyResourceCentre/My Documents/Icon.svg', '/app/main/MyResourceCentre/MyDocuments'),
                new AppMenuItem('MY NEEDS CALCULATOR', ['BasicUser1'], [], 'myHealth-clr-changes-sub', '../../../../assets/common/images/MyResourceCentre/MyNeedsCalculator/Icon.svg', '/app/main/MyResourceCentre/MyNeedsCalculator'),
            ]),
            new AppMenuItem('MY SUPPORT', ['BasicUser1'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/MySupport.svg', '/app/main/MySupport'),
            new AppMenuItem('USER MANAGEMENT', ['AdminUI Administrator'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/users.svg', '/app/main/user'),
            new AppMenuItem('INCOME CONFIRMATION GOAL MANAGEMENT', ['AdminUI Administrator'], [], 'text-left-sidebar pt-1', '../../../../assets/common/images/dart.svg', '/app/main/incomegoalmanagement')
        ]);
    };
    AppNavigationService.prototype.hasOneVisibleSubItem = function (menuItem) {
        var e_1, _a;
        if (menuItem.items.length === 0) {
            return false;
        }
        try {
            for (var _b = __values(menuItem.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                if (this.showMenuItem(item)) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    AppNavigationService.prototype.showMenuItem = function (menuItem) {
        if (menuItem.permissionNames.includes('Pages.Administration.Tenant.SubscriptionManagement') && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }
        var hideMenuItem = false;
        if (menuItem.permissionNames.length > 0 && !this._authService.userPermission.some(function (p) { return menuItem.permissionNames.includes(p); })) {
            hideMenuItem = true;
        }
        if (menuItem.hideFor && this._authService.userPermission.some(function (p) { return menuItem.hideFor.includes(p); })) {
            hideMenuItem = true;
        }
        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }
        return !hideMenuItem;
    };
    return AppNavigationService;
}());
export { AppNavigationService };
