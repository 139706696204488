/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./truID-upload-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/common/file-uploader/file-uploader.component.ngfactory";
import * as i4 from "../../../shared/common/file-uploader/file-uploader.component";
import * as i5 from "../../../shared/common/file-uploader/service/file-storage.service";
import * as i6 from "abp-ng2-module/dist/src/message/message.service";
import * as i7 from "../../../shared/common/IDSVRAuthProviders/auth.service";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "./truID-upload-modal.component";
import * as i10 from "../../../../shared/service-proxies/service-proxies";
import * as i11 from "../../../shared/services/my-financial.service";
import * as i12 from "../../../../shared/NotificationSignalrService/notification-signal-r.service";
var styles_TruIDUploadModalComponent = [i0.styles];
var RenderType_TruIDUploadModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TruIDUploadModalComponent, data: {} });
export { RenderType_TruIDUploadModalComponent as RenderType_TruIDUploadModalComponent };
function View_TruIDUploadModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-stopwatch"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Time remaining: ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFormattedTime(_co.countdown); _ck(_v, 2, 0, currVal_0); }); }
function View_TruIDUploadModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Processing your bank statement... "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-circle-notch fa-spin ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "flex-row"], ["style", "height: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruIDUploadModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.countdown > 0); _ck(_v, 8, 0, currVal_0); }, null); }
function View_TruIDUploadModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "padding-left: 8rem; color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" An error occurred: Please restart the upload. "]))], null, null); }
export function View_TruIDUploadModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileUploader: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-file-uploader", [], null, [[null, "uploadFinished"], [null, "showSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadFinished" === en)) {
        var pd_0 = (_co.onUploadFinished($event) !== false);
        ad = (pd_0 && ad);
    } if (("showSuccess" === en)) {
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_FileUploaderComponent_0, i3.RenderType_FileUploaderComponent)), i1.ɵdid(3, 114688, [[1, 4], ["fileUploader", 4]], 0, i4.FileUploaderComponent, [i5.FileStorageService, i6.MessageService, i7.AuthService, i8.BsModalRef], { storageStrategy: [0, "storageStrategy"] }, { uploadFinished: "uploadFinished" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruIDUploadModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruIDUploadModalComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 4; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isProcessing; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.timeoutError && !_co.isProcessing); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_TruIDUploadModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "truID-upload-modal", [], null, null, null, View_TruIDUploadModalComponent_0, RenderType_TruIDUploadModalComponent)), i1.ɵdid(1, 4308992, null, 0, i9.TruIDUploadModalComponent, [i1.Injector, i10.UploadServiceProxy, i11.MyFinancialService, i10.YodleeServiceProxy, i10.ClaimModifyServiceProxy, i12.NotificationSignalRService, i6.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TruIDUploadModalComponentNgFactory = i1.ɵccf("truID-upload-modal", i9.TruIDUploadModalComponent, View_TruIDUploadModalComponent_Host_0, {}, { onProcessingComplete: "onProcessingComplete" }, []);
export { TruIDUploadModalComponentNgFactory as TruIDUploadModalComponentNgFactory };
