import { Injector, ElementRef, Component, OnInit, AfterViewInit, ViewChild, HostBinding } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './theme3-layout.component.html',
    styleUrls: ['./theme3-layout.component.css'],
    selector: 'theme3-layout',
    animations: [appModuleAnimation()]
})
export class Theme3LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('mHeader') mHeader: ElementRef;
    @ViewChild('mAsideLeft') mAsideLeft: ElementRef;
    @HostBinding('attr.mMenuAsideOffcanvas') mMenuAsideOffcanvas: MenuAsideOffcanvasDirective;
    private _isAuthenticated: boolean;

    constructor(
        injector: Injector,
        private layoutRefService: LayoutRefService,
        private authService: AuthService,
        private route: ActivatedRoute
    ) {
        super(injector);

    }

    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }
    public set isAuthenticated(value: boolean) {
        this._isAuthenticated = value;
    }

    ngOnInit() {
        this.isAuthenticated = this.authService.isLoggedIn();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }

    getTheme(): string {
        try {
            const url = this.route.snapshot['_routerState'].url;
            if (url.includes('ExtQuote') || url.includes('ExtCalculator')) {
                return 'ext-quote';
            }

            return this.currentTheme.baseSettings.menu.asideSkin;
        } catch {
            return this.currentTheme.baseSettings.menu.asideSkin;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.mAsideLeft) { return; }
            this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(this.mAsideLeft);
            if (this.mMenuAsideOffcanvas) {
                this.mMenuAsideOffcanvas.ngAfterViewInit();
            }
        });

        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    }
}
