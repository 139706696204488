import { Keyboard } from '@amcharts/amcharts4/core';
import { Directive, HostListener, ElementRef, SimpleChanges, OnChanges, Input, OnInit } from '@angular/core';
import { CurrencyPipeZar } from '@shared/common/pipes/commonElevate.pipe';

@Directive({ selector: '[inputFormatter]' })
export class InputFormatterDirective implements OnChanges, OnInit {
  @Input() changeDetectValue: any;
  @Input() formatType: string;
  hasFocus: boolean;
  private el: HTMLInputElement;
  private allowedKeycodes = [
    // 0-9 on normal keyboard position.
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    // . on normal keyboard position.
    190,
    // 0-9 on numpad.
    96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
    // . on numpad.
    110,
    // tab, enter & ctrl.
    9, 13, 17,
    // arrows.
    37, 38, 39, 50,
    // backspace & delete
    8, 46
  ];

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipeZar
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (this.formatType === 'currency') {
      this.el.placeholder = 'R';
    } else {
      this.el.placeholder = 'Please enter';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.changeDetectValue.currentValue || changes.changeDetectValue.currentValue === 0) && !this.hasFocus) {
      setTimeout(() => {
        this.el.value = this.getFormattedValue(changes.changeDetectValue.currentValue.toString());
      }, 100);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.hasFocus = true;
    this.el.value = value.toString().replace(/[^0-9\.]+/g, '');
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.hasFocus = false;
    this.el.value = this.getFormattedValue(value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.which === 17) {
      // Add a & c when ctrl is pressed.
      this.allowedKeycodes.push(...[65, 67]);
      return true;
    }

    if (!this.allowedKeycodes.includes(event.which)) {
      return false;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.which === 17) {
      this.allowedKeycodes = this.allowedKeycodes.filter(a => a !== 65 && a !== 67);
    }
  }

  private getFormattedValue(value: string): string {
    if (value === '' || value === null || typeof value === 'undefined') {
      return '';
    }

    if (this.formatType === 'currency') {
      return this.currencyPipe.transform(+value, true);
    }

    if (this.formatType === 'age') {
      return `${value} years old`;
    }

    if (this.formatType === 'minutes') {
      return `${value} minutes`;
    }

    if (this.formatType === 'hours') {
      return `${value} hours`;
    }

    if (this.formatType === 'height') {
      return `${value} m`;
    }

    if (this.formatType === 'weight') {
      return `${value} kg`;
    }

    if (this.formatType === 'cm') {
      return `${value} cm`;
    }

    return value;
  }
}
