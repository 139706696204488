import {Component, Input, OnInit, Output, ViewChild, EventEmitter, ViewEncapsulation, Injector} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {
  FileStorageService,
  StorageStrategies,
  UploadStrategies
} from '@app/shared/common/file-uploader/service/file-storage.service';
import {last, tap} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {MessageService} from '@abp/message/message.service';
import { AuthService } from '../IDSVRAuthProviders/auth.service';
import { FileUploadResponse } from './models/file-upload-response';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploaderComponent implements OnInit {
  @Input('accepts') public accepts = '';
  @Input('max-file-size') public maxFileSize = 1000000;
  // @Input('file-limit') public fileLimit = 10;
  @Input('disabled') public disabled = false;
  @Input('allow-multiple') public allowMultiple = false;
  @Input('upload-strategy') public uploadStrategy = UploadStrategies.Simultaneous;
  @Input('storage-strategy') public storageStrategy: StorageStrategies;
  @Input('file-tags') public fileTags: string[] = [];
  @Input('optional-header-text') public optionalHeaderText = '';
  @Input('show-success') public showSuccess = true;
  @Output() public uploadFinished = new EventEmitter<FileUploadResponse>();

  public isUploading = false;
  public isProcessing = false;

  @ViewChild('uploader') private fileUploader: FileUpload;

  constructor(private fileStorageService: FileStorageService, private msgService: MessageService, private authService: AuthService, public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.checkStorageStrategy();
  }

  /**
   * Event Handler for the request to upload files. This will upload based on the selected Upload Strategy.
   * @param event The event that occurred when the request to upload was made.
   */
  public async uploadHandler(event: any) {
    this.checkStorageStrategy();

   if (!this.isUploading && this.fileUploader.files.length > 0) {
     this.isUploading = true;
     this.fileUploader.disabled = true;

     try {
       switch (this.uploadStrategy) {
         case UploadStrategies.Sequential:
           await this.uploadFilesSequentially();
           break;
         case UploadStrategies.Simultaneous:
           await this.uploadFilesSimultaneously();
           break;
         default:
           await this.uploadFilesSequentially();
           break;
       }
       if (this.showSuccess) {
          this.msgService.success('Uploaded Successfully');
        }
       this.fileUploader.clear();
     } catch (e) {
       this.msgService.error('An error occurred while uploading your files');
     } finally {
       this.isUploading = false;
       this.fileUploader.disabled = false;
       this.fileUploader.progress = 0;
     }
   }
  }

  /**
   * Event Handler to update the progress of a file upload.
   * @param $event The event that occurred. Contains the current progress value.
   */
  public progressReport($event: any) {
    this.fileUploader.progress = $event;
  }

  /**
   * Removes a file from the list of files to be uploaded.
   * @param event Event that occurred when this method was triggered.
   * @param file The selected file to be removed.
   */
  public removeFile(event: Event, file: any) {
    this.fileUploader.remove(event, this.fileUploader.files.indexOf(file));
  }

  /**
   * Formats the raw byte total of a file into a human-readable value.
   * @param bytes The raw byte count of the file
   * @param decimals The number of decimals to be displayed
   */
  public formatBytes(bytes: number|string, decimals = 2) {
    if (!+bytes) { return '0 Bytes'; }

    decimals = decimals < 0 ? 0 : decimals;

    const divisor = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const index = Math.floor(Math.log(+bytes) / Math.log(divisor));

    return `${parseFloat((+bytes / Math.pow(divisor, index)).toFixed(decimals))} ${sizes[index]}`;
  }

  /**
   * Checks if the optional header text is present.
   * @returns {boolean} True if the optional header text is present, false otherwise.
   */
  public hasOptionalHeader(): boolean {
    return this.optionalHeaderText !== null && this.optionalHeaderText !== undefined && this.optionalHeaderText !== '';
  }

  /**
   * Checks that there is a valid Storage Strategy provided in the instantiation of the uploader.
   * @private
   */
  private checkStorageStrategy() {
    if (this.storageStrategy === null || this.storageStrategy === undefined) {
      throw Error('Please select a storage strategy');
    }

    if (!Object.values(StorageStrategies).includes(this.storageStrategy)) {
      throw Error('The Storage Strategy provided is invalid');
    }
  }

  /**
   * Prepares the form data to be uploaded.
   * @param files The files to be uploaded
   * @private
   */
  private prepareFormData(files: File[]): FormData {
    const formData = new FormData();

    formData.append('fileStorageStrategy', this.storageStrategy.toString());
    formData.append('clientId', this.authService.user.profile.sub);
    formData.append('accessToken', this.authService.user.access_token);

    files.forEach((file, index) => {
      formData.append('files', file);
    });

    this.fileTags.forEach((tag, index) => {
      formData.append('tags', tag);
    });

    return formData;
  }

  /**
   * Prepares and uploads each added file individually.
   * @private
   */
  private async uploadFilesSequentially() {
    for (const file of this.fileUploader.files) {
      this.fileUploader.progress = 0;

      const formData = this.prepareFormData([file]);

      await this.processUpload(formData);
    }
  }

  /**
   * Prepares and uploads all added files simultaneously.
   * @private
   */
  private async uploadFilesSimultaneously() {
    const formData = this.prepareFormData(this.fileUploader.files);

    await this.processUpload(formData);
  }

  /**
   * Handles the upload and progress reporting for display on the UI.
   * @param formData The data to be uploaded
   * @private
   */
  private async processUpload(formData: FormData) {
    return new Promise((resolve, reject) => {
      this.fileUploader.progress = 0;

      this.fileStorageService.upload(formData).pipe(
          tap(event => {
            if (event.type === HttpEventType.UploadProgress) {
              this.fileUploader.onProgress.emit(event.loaded / event.total * 100);
            }
          }),
          last()
      ).subscribe({
        next: (val) => {
          if (val.type === HttpEventType.Response) {
            this.uploadFinished.emit(val.body);
          }
          this.fileUploader.onProgress.emit(100);
          resolve();
        },
        error: (err) => reject(err)
      });
    });
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
}
