﻿import { GetQuestionariesModule } from '@app/shared/models_elevate/models';
import { PersonEducation, QuoteGender, SmokerStatus } from './AppEnums';

export class AppConsts {
    static readonly AssessmentType = {
        FamilyAssessment: 'FamilyAssessment',
        EarningAssessment: 'EarningAssessment',
        HomeAssessment: 'HomeAssessment',
        InsuranceAssessment: 'InsuranceAssessment',
        MeidcalInsuranceAssessment: 'MedicalInsuranceAssessment',
        ExpenseAssessment: 'ExpenseAssessment',
        EstimateAssessment: 'EstimateAssessment'
    };

    static readonly ProductIdentifiers = {
        Accelerator: 'Accelerator',
        Legacy: 'Legacy'
    };

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    static readonly FullyUnderwrittenAmount = 1500000;

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static ENV: string;

    static localeMappings: any = [];

    static readonly ClaimTypes = {
        physical_address_provence: 'physical_address_provence',
        nickname: 'nickname',
        Compuscan_ContactNo3: 'Compuscan_ContactNo3',
        ui_MyDocuments: 'ui_MyDocuments',
        Compuscan_LastName: 'Compuscan_LastName',
        Compuscan_DateOfDeceased: 'Compuscan_DateOfDeceased',
        Compuscan_Employer4: 'Compuscan_Employer4',
        broker_qualifications: 'broker_qualifications',
        ui_MyResourceCentre: 'ui_MyResourceCentre',
        ui_MakeClaim: 'ui_MakeClaim',
        locale: 'locale',
        gender_selectable: 'gender_selectable',
        Compuscan_Address3: 'Compuscan_Address3',
        broker_product_specific_training: 'broker_product_specific_training',
        Compuscan_FirstName: 'Compuscan_FirstName',
        FullPortalAccess: 'FullPortalAccess',
        zoneinfo: 'zoneinfo',
        ui_MyNeedsCalculator: 'ui_MyNeedsCalculator',
        allow_credit: 'allow_credit',
        broker_active_start_date: 'broker_active_start_date',
        ui_MyCommandCentre: 'ui_MyCommandCentre',
        preferred_username: 'preferred_username',
        marketing_material_accepted: 'marketing_material_accepted',
        Compuscan_Employer2: 'Compuscan_Employer2',
        ui_AddOnBenefits: 'ui_AddOnBenefits',
        is_vaccinated: 'is_vaccinated',
        broker_cpd_requirements: 'broker_cpd_requirements',
        Compuscan_ContactNo4: 'Compuscan_ContactNo4',
        ui_MyPolicies: 'ui_MyPolicies',
        Compuscan_Employer1: 'Compuscan_Employer1',
        allow_healthcare: 'allow_healthcare',
        broker_profession_id: 'broker_profession_id',
        Compuscan_DateOfMarriage: 'Compuscan_DateOfMarriage',
        smoking_status: 'smoking_status',
        ui_MyRewards: 'ui_MyRewards',
        email: 'email',
        occupation_category: 'occupation_category',
        gender: 'gender',
        terms_conditions_accepted: 'terms_conditions_accepted',
        ui_Beneficiaries: 'ui_Beneficiaries',
        ui_NewPolicy: 'ui_NewPolicy',
        tenant_name: 'tenant_name',
        socio_economic_class: 'socio_economic_class',
        physical_address_country: 'physical_address_country',
        allow_financial: 'allow_financial',
        Compuscan_MaritalStatus: 'Compuscan_MaritalStatus',
        website: 'website',
        ui_MyWearables: 'ui_MyWearables',
        broker_pi_cover: 'broker_pi_cover',
        Compuscan_IdVerified: 'Compuscan_IdVerified',
        broker_experience_start_date: 'broker_experience_start_date',
        role: 'role',
        broker_fi_cover: 'broker_fi_cover',
        phone_number_verified: 'phone_number_verified',
        employer_name: 'employer_name',
        middle_name: 'middle_name',
        Compuscan_Address4: 'Compuscan_Address4',
        education: 'education',
        physical_address_city: 'physical_address_city',
        name: 'name',
        id_number_verified: 'id_number_verified',
        ui_MyBillingInfo: 'ui_MyBillingInfo',
        ui_ElevationFund: 'ui_ElevationFund',
        Compuscan_Address2: 'Compuscan_Address2',
        physical_address_line_2: 'physical_address_line_2',
        Compuscan_ContactNo2: 'Compuscan_ContactNo2',
        allow_finance: 'allow_finance',
        profile: 'profile',
        occupation: 'occupation',
        sub: 'sub',
        ui_MySupport: 'ui_MySupport',
        birthdate: 'birthdate',
        Compuscan_Employer5: 'Compuscan_Employer5',
        ui_MyData: 'ui_MyData',
        picture: 'picture',
        marital_status: 'marital_status',
        automatic_account_registration_credentials_sent: 'automatic_account_registration_credentials_sent',
        family_name: 'family_name',
        city: 'city',
        Compuscan_IdPhoto: 'Compuscan_IdPhoto',
        Compuscan_Address1: 'Compuscan_Address1',
        BrokerageUID: 'BrokerageUID',
        allow_wearable: 'allow_wearable',
        physical_address_code: 'physical_address_code',
        broker_active_end_date: 'broker_active_end_date',
        automatic_account_registration: 'automatic_account_registration',
        ui_MyFinance: 'ui_MyFinance',
        medical_scheme_name: 'medical_scheme_name',
        Compuscan_ContactNo5: 'Compuscan_ContactNo5',
        ui_EditPolicy: 'ui_EditPolicy',
        broker_brokerage_short_code: 'broker_brokerage_short_code',
        email_verified: 'email_verified',
        id_number: 'id_number',
        ui_MyGoals: 'ui_MyGoals',
        gross_income: 'gross_income',
        address: 'address',
        medical_scheme_membership_number: 'medical_scheme_membership_number',
        ui_MyHealthcare: 'ui_MyHealthcare',
        Compuscan_Employer3: 'Compuscan_Employer3',
        physical_address_line_1: 'physical_address_line_1',
        Compuscan_Address5: 'Compuscan_Address5',
        Compuscan_Deceased: 'Compuscan_Deceased',
        ui_BillingInfo: 'ui_BillingInfo',
        ui_OtherRewards: 'ui_OtherRewards',
        ui_MyCredit: 'ui_MyCredit',
        Compuscan_ContactNo1: 'Compuscan_ContactNo1',
        ui_MyNews: 'ui_MyNews',
        broker_supervision: 'broker_supervision',
        given_name: 'given_name',
        medical_scheme_option: 'medical_scheme_option',
        medical_scheme_membership_number_verified: 'medical_scheme_membership_number_verified',
        phone_number: 'phone_number',
        physical_address_suburb: 'physical_address_suburb',
        needs_assessment: 'needs_assessment',
        needs_assessment_date: 'needs_assessment_date',
        credit_check: 'credit_check',
        credit_check_date: 'credit_check_date',
        proof_of_income: 'proof_of_income',
        proof_of_income_date: 'proof_of_income_date',
        net_income: 'net_income',
        needs_assessment_life_cover: 'needs_assessment_life_cover',
        needs_assessment_income_protection: 'needs_assessment_income_protection',
        needs_assessment_severe_illness_cover: 'needs_assessment_severe_illness_cover',
        needs_assessment_disability_impairment_cover: 'needs_assessment_disability_impairment_cover',
        hra_intro_status: 'hra_intro_status',
        hra_intro_date: 'hra_intro_date',
        hra_nutrition_status: 'hra_nutrition_status',
        hra_nutrition_date: 'hra_nutrition_date',
        hra_sleep_status: 'hra_sleep_status',
        hra_sleep_date: 'hra_sleep_date',
        hra_mind_status: 'hra_mind_status',
        hra_mind_date: 'hra_mind_date',
        hra_body_status: 'hra_body_status',
        hra_body_date: 'hra_body_date',
        hra_exercise_status: 'hra_exercise_status',
        hra_exercise_date: 'hra_exercise_date',
        hra_goal_completed: 'hra_goal_completed',
        replace_existing_cover: 'replace_existing_cover',
        replace_existing_cover_date: 'replace_existing_cover_date',

        kept_existing_life_cover: 'kept_existing_life_cover',
        kept_existing_life_cover_date: 'kept_existing_life_cover_date',
        kept_existing_income_protection: 'kept_existing_income_protection',
        kept_existing_income_protection_date: 'kept_existing_income_protection_date',
        kept_existing_disability_impairment_cover: 'kept_existing_disability_impairment_cover',
        kept_existing_disability_impairment_cover_date: 'kept_existing_disability_impairment_cover_date',
        kept_existing_severe_illness_cover: 'kept_existing_severe_illness_cover',
        kept_existing_severe_illness_cover_date: 'kept_existing_severe_illness_cover_date',

        saving_goal_completed: 'saving_goal_completed',
        elevate_me_faster_goal_completed: 'elevate_me_faster_goal_completed'
    };

    static QuircDecisionName = {
        Included: 'Included',
        Excluded: 'Excluded',
        Postpone: 'Postpone'
    };

    static ProductTypes = {
        Automatic: 'Automatic',

    };

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'ElevateMe'
    };

    static readonly AnswerType = {
        BasicAnswerProvider: 'BasicAnswerProvider',
        MultipleSelectionAnswerProvider: 'MultipleSelectionAnswerProvider',
        SingleEntryAnswerProvider: 'SingleEntryAnswerProvider',
        SelectionAnswerProvider: 'SelectionAnswerProvider',
        DateOfBirth: 'DateOfBirth'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly SmokerStatus = {
        Smoker: 'Smoker',
        NonSmoker: 'Non-Smoker'
    };

    static readonly disclaimerText = {
        StartingAndEndingsOfEveryParagraph: [
            'In order for us to provide you with##sent to us being allowed to:',
            '- Store and process you##meet our obligations under your policy\/ies.',
            '- Collect, store and process your information from thi##ms and conditions under your Elevate Life policy\/ies.',
            '- Share your information, only when absolutely nec##tain your policy\/ies, and assess claims made by you.',
            'To process your application,##against your medical history.', 'You must give us your consent to be##available on third-party databases.',
            'This may inc##our HIV status.',
            'This does not remove or red##life of your Elevate Life policies.',
            'Elevate Life (Pty) Ltd. ##insurance products.', 'All Elevate ##(FSP 7370).', 'Elevate is independent ##agent of, Centriq.',
            'Centriq and Elevate Life have##underwritten by Centriq.', 'In addition, Centriq pays ##writing your policy.', 'The following questions##person to be insured.', 'All information shared ##under the Elevate Life policy.',
            'It is really important##reject your claims.', 'At Elevate Life,##remains fully secure.', 'To provide you with##information that we request.', 'Inaccurate and/or incomplete##claims not being paid!',
            'In order for us##provide us with consent to:', 'In order for us to provide##you must consent to us being allowed to:', '-	Store and process your information shared##course of business,', '-	Collect your information from third-parties,##otherwise process such information,',
            '-	Share your information with Centriq and##any claims made by you,', '-	Share information with other insurers##risks proposed for insurance.', 'If you continue, you are choosing##Life policy offered on this page.',
            'By choosing to do so, the quote on##for an Elevate policy on these terms.', 'Instead, you will need to complete all##honestly, and accurately to get your revised quote.',
            'We will use the additional information to be##to inform the details of your revised quote.', 'It is possible that based##additional information that you share:', '- the premium for your revised qu##gher than the one on this page,',
            '- additional term##o your Elevate Life policy,', '- we choose to decline you##ation for an Elevate Life policy.',
            '- Store and process you##the normal course of business,',
            '- Collect your information from thi##and otherwise process such information,',
            '- Share your information with Centriq##and\/or assess any claims made by you,',
            '- Share information with other insurers in##assessment of risks proposed for insurance.'
        ]
    };

    static readonly ElevateGender = {
        Male: 'Male',
        Female: 'Female',
        Nonbinary: 'Non-binary'
    };

    static readonly MaritialStatus = {
        Single: 'Single',
        Married: 'Married',
        Domestic_partnership: 'Domestic partnership',
        Cohabiting: 'Cohabiting',
        Divorced: 'Divorced',
        Widowed: 'Widowed'
    };

    static readonly QuoteEducation = {
        Nomatric: 'No matric',
        Matric: 'Matric',
        Diplomalessthan: 'Diploma (less than 4 years)',
        Diplomaequalorgreater: 'Diploma (4 years or more)',
        University: '3 year University / Undergraduate degree',
        University4Years: '4 year University / Undergraduate degree',
        Postgraduate: 'Postgraduate study'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly allowhealthcare = 'allow_healthcare';
    static readonly allowWearable = 'allow_healthcare';
    static readonly allowFinancial = 'allow_financial';
    static readonly allowCredit = 'allow_credit';

    static readonly SupportedMedicalAids = {
        Medshield: 'Medshield Medical Scheme',
        Bestmed: 'Bestmed Medical Scheme'
    };

    static readonly contactSupport = 'CONTACT SUPPORT';

    static readonly tryAgain = 'TRY AGAIN';

    static readonly healthCareSucees = 'Please note that healthcare data can take up to <strong><br><u>1 hour</u></strong> to be visualized';
    static readonly CreditSucees = 'Please note that credit data can take up to <strong><br><u>1 hour</u></strong> to be visualized';
    static readonly FinancialSuccess = 'Please note that financial data can take up to <strong><br><u>1 hour</u></strong> to be visualized';


    static readonly HelthCareRetryMessage = '<p>We could not validate your membership details</p><p><strong>Please try again!</strong></p><p>Click on Contact Support for assistance </p>';

    static readonly requirementTyoe = {
        Selectable: 'Selectable',
        Automatic: 'Automatic'
    };

    static readonly quircSubmittingQuestionAnalysis = {
        PolicyStatusName: {
            Referred: 'Referred',
            Declined: 'Declined'
        }

    };

    static readonly QuoteStatus = {
        Not_Accepted: 'Not_Accepted',
        Accepted: 'Accepted'
    };

    static readonly UnderWritingStatus = {
        NotStarted: 1,
        InProgress: 2,
        Submitted: 3
    };

    static readonly QuoteRoles = {
        Life_Insured: 'Life_Insured',
        Premium_Payer: 'Premium_Payer'
    };

    static readonly PolicyRoles = {
        Beneficiary: 'Beneficiary',
        Premium_payer: 'Premium_payer'
    };

    static readonly GoalCategoreis = {
        Wearables: 'Wearables',
        Healthcare: 'Healthcare',
        Credit: 'Credit',
        Financial: 'Financial',
        Vaccination: 'Vaccination',
        HRA: 'HRA',
        ElevateMeFaster: 'ElevateMeFaster'
    };

    static readonly ElevateMendixEndpoints = {
        addExclusion: '/AddExclusionSet?quoteGuid={QUOTE_ID}',
        addIndividualExclusionSet: '/AddIndividualExclusionSet?quoteGuid={QUOTE_ID}',
        addNonIndividualExclusionSet: '/AddNonIndividualExclusionSet?quoteGuid={QuoteGUID}&exclusionCode={ExclusionSetShortCode}',
        createBeneficiary: '/CreateBeneficiary?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        createPerson: '/PutPerson?personGUID={personGUID}',
        createQuote: '/CreateQuote',
        deleteRiderProduct: '/DeleteRiderProductQuoted?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        getAllEventSet: '/GetAllEventSet',
        getBeneficiariesForProductQuoted: '/GetBeneficiariesForProductQuoted?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        getEventSetByShortCode: '/GetEventSetByShortCode?shortCode={ShortCode}',
        getPatternSet: '/GetPatternSet?guid={GUID}',
        getPerson: '/GetPerson?personGUID={personGUID}',
        getPolicy: '/GetPolicy?policyGUID={PolicyGUID}',
        getProductQuoted: 'quotation/quote/{Quote_GUID}/productQuoted',
        getAllProduct: '/GetAllProduct?GenerationName={GENERATION_NAME}',
        getAQuote: '/GetQuote?quoteGUID={Quote_GUID}',
        getQuoteOnAPersonBasedOnRole: '/GetQuotesOnPerson?personGUID={PersonGUID}&role={Role}',
        getRateTableEntryFlat: '/PostRateTableEntriesFlatData',
        getQuotedRider: '/GetQuotedRider?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        acceptQuote: 'quotation/quote/{Quote_GUID}/accept/manualCheck/{ManualCheckDigit}',
        postProductQuoted: '/PostProductQuoted?quoteGuid={Quote_GUID}',
        putProductQuoted: '/PutProductQuoted?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        createRider: '/CreateRiderProduct?quoteGuid={Quote_GUID}&productQuotedGuid={MainProductQuoted_GUID}',
        updateRider: '/UpdateRiderProduct?quoteGuid={Quote_GUID}&productQuotedGuid={MainProductQuoted_GUID}&riderQuotedId={Rider_Quoted_GUID}',
        updateProductQuoted: '/UpdateProductQuoted?quoteGuid={Quote_GUID}&productQuotedGuid={ProductQuoted_GUID}',
        updateQuote: '/UpdateQuote?quoteGuid={Quote_GUID}',
        updateRiderProductQuoted: '/quotation/quote/{Quote_GUID}/productQuoted/{MainProductQuoted_GUID}/riderProductQuoted/{RiderProductQuoted_GUID}',
        getContract: '/GetPolicyContract?policyId={policyId}',
        getBalance: '/GetBalance?accountGuid={AccountGUID}',
        getTransactions: '/GetTransactions?subAccountGuid={SubAccountGUID}',
        getBanks: '/GetBanks',
        getBrokerage: '/GetBrokerage?brokerageGuid={BrokerageGUID}',
        updateBeneficiaries: '/UpdatePolicyAndBeneficiaries?policyGuid={PolicyGUID}&productGuid={ProductGUID}'
    };

    static readonly HraQuestionTypes = {
        SINGLE_SELECT: 'SINGLE_SELECT',
        MULTI_SELECT: 'MULTI_SELECT',
        NUMBER_INPUT: 'NUMBER_INPUT',
        CALC: 'CALC',
        INDEX: 'INDEX',
        REFERENCE: 'REFERENCE',
        SLIDER: 'SLIDER',
        DEFAULT: 'DEFAULT'
    };

    static readonly HraQuestionnaireStatuses = {
        SAVED: 'Saved',
        COMPLETED: 'Completed',
        RESET: 'Reset'
    };

    static readonly HraKeys = {
        INTRO: 'Intro',
        MY_EXERCISE: 'MyExercise',
        MY_HEALTHY_BODY: 'MyHealthyBody',
        MY_HEALTHY_MIND: 'MyHealthyMind',
        MY_NUTRITION: 'MyNutrition',
        MY_SLEEP: 'MySleep'
    };

    static readonly QuircCategories = {
        lifeCover: 'Life Cover',
        severeIllness: 'Severe Illness Cover',
        impairmentCover: 'Impairment Cover',
        incomeProtection30: 'Income Protection 30 day WP',
        incomeProtection90: 'Income Protection 90 day WP'
    };

    static readonly Endpoints = {
        autoRegister: '/api/DirectSales/AutoRegister',
        getAccessToken: '/api/services/app/TokenIdentiyProvider/GetTokenProvider',
        createOrUpdateApplicant: '/CreateOrUpdateApplicant',
        clonePolicy: '/ClonePolicy',
        createOrUpdatePolicy: '/CreateOrUpdatePolicy',
        getQuestionnaireProfileVersion: '/GetQuestionnaireProfileVersion',
        getConditionList: '/GetConditionsFromQuestion',
        getPolicyList: '/GetPolicyList',
        getPolicySchedule: '/GetPolicySchedule',
        getSetupTableData: '/GetSetupTableData',
        submitQuestionnaire: '/SubmitQuestionnaire',
        updatePolicyStatus: '/UpdatePolicyStatus',
        sendNotifications: '/api/IntermediateProcess/SendNotifications',
        addBeneficiary: '/api/UserBenefiaciary/AddBenficiary',
        deleteBeneficiary: '/api/UserBenefiaciary/DeleteBeneficiary/{BENEFICIARY_GUID}/Policy/{POLICY_GUID}',
        getBeneficaryFromIdp: '/api/UserBenefiaciary/{BENEFICIARY_GUID}',
        getBeneficiariesForPolicy: '/api/UserBenefiaciary/Policy/{POLICY_GUID}',
        quirkDictonary: 'quirk-questions-dictonary-value.json',
        socioEconomicDimension: 'social-economic-dimension.json',
        CreateExtQuoteDetails: '/api/ExtQuote',
        getQuoteDetailsByRefernceId: '/api/ExtQuote?referenceId=',
        CreateExtQuoteWithSliderDetails: '/api/ExtQuote/GetSliderData',
        queuePolicy: '/api/IntermediateProcess/PolicySync?policyId=',
        queueUserUpdate: '/api/IntermediateProcess/UserSync?userId=',
        queuePolicyForEmailNotification: '/api/DirectSales/QueueUpEntityForPolicyNotification?email={EMAIL}&policy={POLICY_ID}',
        queuePolicyReferencesMail: '/api/DirectSales/QueueUpPolicyReferencesMail',
        getMedicalSchemes: '/api/DirectSales/MedicalSchemes',
        sendQuote: '/api/NeedsAssesments/SendQuote',
        requestUserOtp: '/Account/RequestUserOtp?userId='
    };

    static readonly productQuoted = '/productQuoted';

    static readonly PolicyStatus = {
        AcceptedFullyUnderwrittenNewSale: 'Accepted_Fully_Underwritten_New_Sale',
        AcceptedNotUnderwritten: 'Accepted_Not_Underwritten',
        AcceptedNotUnderwrittenSecondaryCover: 'Accepted_Not_Underwritten_Secondary_Cover',
        ClaimApproved: 'ClaimApproved',
        AcceptedNotUnderwrittenEndorsed: 'Accepted_Not_Underwritten_Endorsed',
        PreInception: 'Pre_Inception',
        AcceptedFullyUnderwrittenEndorsed: 'Accepted_Fully_Underwritten_Endorsed',
        ClaimInAssessment: 'Claim_In_Assessment',
        AcceptedFullyUnderwritten_Endorsement_Pending: 'Accepted_Fully_Underwritten_Endorsement_Pending',
        LapsedCancellation: 'Lapsed_Cancellation',
        LapsedNoPayment: 'Lapsed_No_Payment'
    };

    static readonly ConditionListExternalId = {
        diagnosedConditionList: 'Diagnosed_condition_disclosure',
        occupationConitionList: 'occupation'
    };

    static readonly CoverStatus = {
        Active: 'Active'
    };

    static readonly ProductConfigCategory = {
        ComplimentaryBenefits: 'Complimentary Benefits',
    };

    static readonly KeyVaultSecrets = {
        cedantId: 'QuirkApiDetails.CedantId',
        quirkRootUrl: 'QuirkApiDetails.RootUrl',
        apimElevateAccpendpoint: 'Apim.ElevateAccpendpoint',
        apimClientEndpoint: 'Apim.ClientEndpoint',
        QuirkApiDetailsQuestionMapping: 'QuirkApiDetails.QuestionMapping',
        MicroServicesExtQuote: 'MicroServices.ExtQuote',
        AppGetBrokerCommissionFuncUrl: 'App.GetBrokerCommissionFuncUrl',
        ActiveGenerationName: 'ActiveGenerationName',
        GetMaxSumAssuredFuncUrl: 'GetMaxSumAssuredFuncUrl',
        GoogleRecaptchaKey: 'GoogleRecaptcha.Key',
        MicroServicesExtQuoteVendorId: 'MicroServices.ExtQuote.VendorId',
        MicroServicesExtQuoteOcpApimSubscriptionKey: 'MicroServices.ExtQuote.OcpApimSubscriptionKey',
        BrokerId: 'BrokerId',
        AppConfigAngularProductConfigUrl: 'AppConfigAngular.ProductConfigUrl',
        AppConfigAngularQuirckMendixMappingUrl: 'AppConfigAngular.QuirckMendixMappingUrl',
        GeneralExclusions: 'GeneralExclusions',
        PortalSettingsauthority: 'PortalSettings.authority'
    };

    static readonly ConfigEndpoint = {
        ProductConfig: '/assets/data/product-config.json'
    };

    static readonly configProducts = {
        Disability: 'Principal Disability Protection',
        Life: 'Principal Life Protection',
        SevereIllnessAccelerator: 'Principal Severe Illness Protection - Accelerator',
        FullTierBooster: 'Principal Full Tier Booster',
        SevereIllness: 'Principal Severe Illness Protection',
        SelfEmployedEnhancer: 'Principal Self-Employed Enhancer',
        AlternateSelfEmployedEnhancer: 'Principal Self Employed Enhancer',
        LowIncomeValue: 6000,
        OwnOccupationBooster: 'Principal Own Occupation Booster',
        PrincipalDisabilityCapitalProtectionAccelerator: 'Principal Disability Capital Protection - Accelerator',
        PrincipalCapitalDisability: 'Principal Disability Capital Protection'
    };

    static readonly NeedsCalcProducts = {
        CarHousehold: 'Car and household',
        Life: 'Life',
        SevereIllness: 'Severe Illness',
        DisabilityLumpSum: 'Disability Lump Sum',
        IncomeProtection: 'Income Protection',
        MedicalAid: 'Medical aid'
    };

    static readonly DND_Questions = {
        DND_Sex: 'DND_sex',
        DND_sales_channel: 'DND_sales_channel',
        DND_Show_products: 'DND_Show_products',
        DND_SA_ID_number: 'DND_SA_ID_number',
        DND_na_indicator: 'DND_na_indicator',
        DND_existing_life_cover_na: 'DND_existing_life_cover_na',
        DND_existing_income_protection_na: 'DND_existing_income_protection_na',
        DND_existing_disability_impairment_cover_na: 'DND_existing_disability_impairment_cover_na',
        DND_existing_severe_illness_cover_na: 'DND_existing_severe_illness_cover_na',
        DND_Credit_check: 'DND_Credit_check',
        DND_Income_confirmation: 'DND_Income_confirmation',
        DND_Scheme_Data: 'DND_Scheme_Data',
        DND_duration_on_scheme: 'DND_duration_on_scheme',
        DND_Access_to_scheme_data: 'DND_Access_to_scheme_data',
        Health_data_consent_real_time: 'Health_data_consent_real_time'
    };

    static readonly ParentSteps = {
        PersonalInfo: 'Personal Info',
        Disclaimers: 'Disclaimers',
        Medical: 'Medical',
        Additionalinfo: 'Additional info'
    };


    static readonly QuirckExtQuestionId = {
        Age: 'age',
        Gender: 'gender',
        GrossMonthlyIncome: 'monthly_income',
        NetMonthlyIncome: 'net_monthly_income',
        ExistingLifeCover: 'Existing_life',
        ExistingIncomeProtectionCover: 'Existing_IP',
        ExistingImpairmentCover: 'Existing_TPD',
        ExistingSevereIllnessCover: 'Existing_CI',
        IsSelfEmployed: 'self_employed',
        ProductCategory: 'ProductCategory',
        NeedsAnalysis: 'DND_na_indicator',
        HouseHoldIncome: 'Household_income',
        EmploymentCategory: 'Occupation',
        PolicyReason: 'Reason_for_policy',
        SmokerStatus: 'smoker_status',
        Education: 'education',
        Residency: 'Residency',
        MartitalStatus: 'marital_status',
        CustomNetIncome: 'custom_net_income',
        CustomNeedsAssessment: 'custom_needs_assessment',
        CustomProofOfIncome: 'custom_proof_of_income',
        CustomCreditCheck: 'custom_credit_check',
        DndCreditCheck: 'DND_Credit_check',
        DndIncomeConfirmation: 'DND_Income_confirmation',
        DndNaIndicator: 'DND_na_indicator',
        AddingOrReplacing: 'Adding_or_replacing',
        AddingOrReplacingNa: 'Adding_or_replacing_with_NA',
        ExistingCI: 'Existing_CI',
        ReplaceExistingCover: 'replace_existing_cover'
    };

    static readonly Occupations = {
        SelfEmployed: 'SelfEmployed',
        Employed: 'Employed',
        Unemployed: 'Unemployed',
        Student: 'Student',
        HouseWifeOrHouseHusband: 'HouseWifeOrHouseHusband'
    };

    static readonly NeedsAnalysis = {
        None: 'None',
        Partial: 'Partial',
        Full: 'Full'
    };

    static readonly CommissionTypes = {
        asAndWhen: 'As-and-when',
        installment: 'Installment',
        upfront: 'Upfront'
    };

    static readonly ProductNames = {
        Life: 'Life Protection'
    };

    static readonly AggregationCategories = {
        lifeProtection: 'Life Protection',
        severeIllnessProtection: 'Severe Illness Protection',
        impairmentProtection: 'Impairment Protection',
        disabilityProtection: 'Disability Protection',
        ownOccupation: 'Own Occupation'
    };

    static readonly PolicyStatuses = {
        acceptedFullyUnderwrittenEndorsed: 'Accepted_Fully_Underwritten_Endorsed',
        acceptedFullyUnderwrittenEndorsementPending: 'Accepted_Fully_Underwritten_Endorsement_Pending',
        acceptedFullyUnderwrittenNewSale: 'Accepted_Fully_Underwritten_New_Sale',
        acceptedNotUnderwritten: 'Accepted_Not_Underwritten',
        acceptedNotUnderwrittenEndorsed: 'Accepted_Not_Underwritten_Endorsed',
        claimApproved: 'Claim_Approved',
        lapsedCancellation: 'Lapsed_Cancellation',
        preInception: 'Pre_Inception'
    };

    static readonly ExtQuoteGenders = [
        {
            description: AppConsts.ElevateGender.Male,
            value: QuoteGender.Male
        },
        {
            description: AppConsts.ElevateGender.Female,
            value: QuoteGender.Female
        },
        {
            description: AppConsts.ElevateGender.Nonbinary,
            value: QuoteGender.NonBinary
        }
    ];

    static readonly ExtQuoteEducationLevels = [
        {
            description: AppConsts.QuoteEducation.Nomatric,
            value: PersonEducation.NoMatric
        },
        {
            description: AppConsts.QuoteEducation.Matric,
            value: PersonEducation.Matric
        }, {
            description: AppConsts.QuoteEducation.Diplomalessthan,
            value: PersonEducation.DiplomaLessThanFourYears
        }, {
            description: AppConsts.QuoteEducation.Diplomaequalorgreater,
            value: PersonEducation.DiplomaFourYearsOrMoreThan
        }, {
            description: AppConsts.QuoteEducation.University,
            value: PersonEducation.ThreeYearsUniversityUnderGraduateDegree
        }, {
            description: AppConsts.QuoteEducation.University4Years,
            value: PersonEducation.FourYearsUniversityUnderGraduateDegree
        }, {
            description: AppConsts.QuoteEducation.Postgraduate,
            value: PersonEducation.PostGraduateStudy
        }
    ];

    static readonly ExtQuoteSmokingStatuses = [
        {
            description: AppConsts.SmokerStatus.NonSmoker,
            value: SmokerStatus.NonSmoker
        },
        {
            description: AppConsts.SmokerStatus.Smoker,
            value: SmokerStatus.Smoker
        }
    ];

    static readonly CustomFullQuoteQuestions: Array<GetQuestionariesModule.IQuestion> = [
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'No',
                    Value: '0',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Yes',
                    Value: '1',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.CustomNeedsAssessment,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: null,
            Text: 'Has a needs assessment been done?',
            Title: 'Custom needs assessment',
            QuestionType: 1,
            ValueGiven: undefined,
            overrideVisibility: true
        },
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'No',
                    Value: '0',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Yes',
                    Value: '1',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.CustomProofOfIncome,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: null,
            Text: 'Has proof of income been recorded?',
            Title: 'Custom proof of income',
            QuestionType: 1,
            ValueGiven: undefined,
            overrideVisibility: true
        },
        {
            AllowMultiAnswers: false,
            Answer: [
                {
                    AnswerSequence: 1,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'No',
                    Value: '0',
                    id: null
                },
                {
                    AnswerSequence: 2,
                    AnswerType: 'BasicAnswerProvider',
                    ApplicantAttributeName: null,
                    AutoComplete: false,
                    DataType: 'String',
                    OutComes: [],
                    OverrideAnswer: false,
                    Prompt: '',
                    Text: 'Yes',
                    Value: '1',
                    id: null
                }
            ],
            AnswerRequired: true,
            ExternalReferenceId: AppConsts.QuirckExtQuestionId.CustomCreditCheck,
            Id: null,
            IsVisibleAtStart: true,
            Prompt: '',
            QuestionSequence: null,
            Text: 'Has a credit check been done?',
            Title: 'Custom credit check',
            QuestionType: 1,
            ValueGiven: undefined,
            overrideVisibility: true
        }
    ];
}
