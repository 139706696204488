import { HttpEvent } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthGuard } from '@app/shared/common/IDSVRAuthProviders/auth.guard.service';
import { FileUploaderComponent } from '@app/shared/common/file-uploader/file-uploader.component';
import { FileUploadResponse } from '@app/shared/common/file-uploader/models/file-upload-response';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UploadServiceProxy } from '@shared/service-proxies/service-proxies';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'elevate-me-faster-upload-modal',
    templateUrl: './elevate-me-faster-upload-modal.component.html',
    styleUrls: ['./elevate-me-faster-upload-modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ElevateMeFasterUploadModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    @Output() onUploadComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    selectedFile: File;
    isLoading: boolean;
    constructor(
        injector: Injector,
        private uploadService: UploadServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
    }

    fileSelected(event: File) {
        this.selectedFile = event;
    }

    onUploadFinished(uploadResponse: any) {
        const message =
        'A user has uploaded documents for their Elevate Me Faster goal.\n\n' +
        'User Guid: ' + this._authGuard.user.profile.sub + '\n' +
        'Name: ' + this._authGuard.user.profile.given_name + '\n' +
        'Surname: ' + this._authGuard.user.profile.family_name + '\n' +
        'Email: ' + this._authGuard.user.profile.email + '\n' +
        'Phone: ' + this._authGuard.user.profile.phone_number + '\n' +
        'Document: ' + uploadResponse.result[0].url + '\n\n' +
        'Please review the documentation and update their claims accordingly.';

        this._emailService.sendGenericMail('Elevate Me Faster goal documentation upload - ' + this._authGuard.user.profile.given_name + ' ' + this._authGuard.user.profile.family_name, message, 'bradley.deklerk@elevate.co.za').subscribe(() => {
            console.log('Elevate Me Faster goal documentation upload email sent');
        });
        this.onUploadComplete.next(true);
    }
}
